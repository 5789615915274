import React, { useRef, useState  } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';


function DlgUser({showDialogueSingleUser, setShowDialogueSingleUser, setSelectedUserInfo, selectedUserInfo, personAdmin}) {

    const nameRef                                = useRef();
    const surnameRef                             = useRef();
    const emailRef                               = useRef();
    const roleRef                                = useRef();
    const phonenumberRef                         = useRef();
    const bioRef                                 = useRef();
    const professionRef                          = useRef();

    const [editableFields, setEditableFields]    = useState(true);
    const [processing, setProcessing]            = useState(false);

    const [msgFeedback, setMsgFeedback]                          = useState("");
    const [msgClass, setMsgClass]                                = useState("");

    const [sideMsgeFeed, setSideMsgFeed]                         = useState("");
    const [sMsgClass, setSMsgClass]                              = useState("");

    const allowEditingSwitch = () => {       
        setEditableFields(!editableFields);
    }
    const deleteThisField = async () => {
       // console.log("Delete this guy");
       // console.log(selectedUserInfo);

        try{

            setMsgFeedback("");
            setMsgClass("");
         
            //console.log(selectedUserInfo)
           // console.log(selectedUserInfo.extras[0]);
            const extraSup = selectedUserInfo.extras[0];            
            
               const response = await axios.delete(CONSTANTS.API_URL+"users/terminate/" + selectedUserInfo._id, {
                    headers: {
                        token: "Bearer "+ personAdmin.accessToken
                    }
                });
               //console.log(response.data);
               setMsgFeedback(response.data);
               if(extraSup.supplierId){
                    //console.log("Supplier ID Exists");
                    const newDel = {
                        "supplier": extraSup.supplierId,
                        "user": selectedUserInfo._id
                    }

                    const reSupUser = await axios.put(CONSTANTS.API_URL+"suppliers/eliminate" , newDel, {
                            headers: {
                                token: "Bearer "+ personAdmin.accessToken
                            }
                        });
                    console.log(reSupUser.data);
                }
               setMsgClass("alert-warning");
               setProcessing(false);
              setSelectedUserInfo([]);
           
        }catch(err){
            setMsgFeedback("Something went wrong, please try again later");
            setMsgClass("alert-danger");
            setProcessing(false);
            //console.log(err);
        }
    }
    async function handleFormUpdatreUser(e) {
        e.preventDefault();  
        setProcessing(true);   

        try{

            setMsgFeedback("");
            setMsgClass("");
            var rule = false;

            if(roleRef.current.value === "admin"){
               rule = true;
            }
            let profilelink = "-";
            if(selectedUserInfo.profilePic !== '-'){
                profilelink = selectedUserInfo.profilePic;
            }
            const userOb = {
                "userId": selectedUserInfo._id,
                "name" : nameRef.current.value,
                "surname" : surnameRef.current.value,
                "email" : emailRef.current.value,
                "isAdmin" : rule,
                "profilePic" : profilelink,
                "roles" : roleRef.current.value,
                "bio" : bioRef.current.value,
                "phonenumber" : phonenumberRef.current.value,
                "profession" : professionRef.current.value
               }
              // console.log(userOb);
               
               const response = await axios.put(CONSTANTS.API_URL+"users/updateuser", userOb, {
                    headers: {
                        token: "Bearer "+ personAdmin.accessToken
                    }
                });
               //console.log(response.data);
               setMsgFeedback("User updates have been updated.");
               setMsgClass("alert-success");
               setProcessing(false);
               setEditableFields(true);
               setSelectedUserInfo(userOb);

        }catch(err){
            setMsgFeedback("Something went wrong, please try again later");
            setMsgClass("alert-danger");
            setProcessing(false);
            //console.log(err);
        }
            
    }

    const templateRolesOption = (roleItem) => {
        //console.log(rowData);
        
        switch(roleItem) {
            case 'admin':
              return <select ref={roleRef} className="form-control wide100" readOnly={editableFields}>
                        <option value="admin">Admin</option>
                        <option value="standard">Standard</option>
                        <option value="supplier">Supplier</option>
                    </select>;
            case 'supplier':
                return <select ref={roleRef} className="form-control wide100" readOnly={editableFields}>
                            <option value="supplier">Supplier</option>
                            <option value="standard">Standard</option>
                            <option value="admin">Admin</option>    
                      </select>;
            default:
                return <select ref={roleRef} className="form-control wide100" readOnly={editableFields}>                        
                          <option value="standard">Standard</option>
                          <option value="admin">Admin</option>                          
                          <option value="supplier">Supplier</option>      
                    </select>;
          }
        //setSelectedUserInfo(rowData);
       
      }

      const sendResetPassword = async () => {
    /*  console.log("Lion");
        console.log(emailRef.current.value);
        console.log("*******************");
        console.log(selectedUserInfo._id);
        console.log(selectedUserInfo);
        */

        const newPassword = makeid(7);
        //console.log(newPassword);

        const dataObs = {
            "id": selectedUserInfo._id,
            "email": selectedUserInfo.email,
            "password": newPassword
        }

        setProcessing(true);
        
        try{
            //go reset the password
            const response = await axios.put(CONSTANTS.API_URL+"users/passreset", dataObs, {
                headers: {
                    token: "Bearer "+ personAdmin.accessToken
                }
              });
            
            //console.log("XXXXXXXXXXXX")
            //console.log(response.data);
            const getemailFeedback = await axios.get("https://api.picnick.co.za/emailing/email.php?email_test=picnickCutterX&eemail=" + selectedUserInfo.email + "&epass=" + newPassword);
            console.log(getemailFeedback.data);

            setSideMsgFeed(getemailFeedback.data.message + " "+ newPassword);
            setSMsgClass("alert-success")
            setProcessing(false);
        }catch(er){
            console.log(er);
            setProcessing(false);
        }
        console.log("<=======================>");
      }

    function makeid(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
        return result;
     }

  return (
    <div className="block-comp">
        <Dialog header="User Details"  visible={showDialogueSingleUser} onHide={() => setShowDialogueSingleUser(false)} style={{ width: '50vw' }}
            footer={
            <div>
                {/* link button */}
              
                <Button className="btn btn-picky" label="Close" onClick={() => setShowDialogueSingleUser(false)} />
            </div>
            }>
                  <div className="modal-body-content">
                     <div className="flexme">
                        <div className="image-dialogue-box pd25">
                            <div className="al-box pdtb10">
                                {
                                    sideMsgeFeed && (
                                        <div className={"alert-area " + sMsgClass}>
                                            {sideMsgeFeed}
                                        </div>
                                    )
                                    
                                }
                            </div>
                            <div className="large-profile">
                                {
                                   (
                                    selectedUserInfo.profilePic === '-' ? <div className='dsh-profile lg-profe' style={{ 
                                            backgroundImage: `url(${CONSTANTS.IMAGE_PROFILE})` 
                                        }}></div> 
                                        : 
                                        <div className="dsh-profile lg-profe-ds" style={{
                                            backgroundImage: `url(${selectedUserInfo.profilePic})` 
                                        }}></div>
                                    )
                                }
                            </div>
                            <div className="item-product-sct mgtop30">
                                <h4>Send Reset Password</h4>
                                <button className="btn btn-picky btsm1 mr-2" onClick={sendResetPassword}>
                                    SEND NOW
                                </button>
                            </div>
                        </div>
                        <div className="profile-quick-edit wide50">
                        
                                {
                                  msgFeedback && (
                                    <div className="pdtb10">
                                        <div className={"alert-area " + msgClass}>
                                            {msgFeedback}
                                        </div>  
                                    </div>
                                  )
                                }
                         
                            <button className="btn btn-picky btsm1 mr-2" onClick={allowEditingSwitch}>Edit </button>
                            {
                                (editableFields === false) && (                                    
                                    <button className="btn btn-picky btsm1" disabled={processing} onClick={deleteThisField}>Delete</button>        
                                )
                            }
                            <form onSubmit={handleFormUpdatreUser}>
                                <div className="form-group">
                                      <div className="lbldesc">Name:</div>
                                      <input type="text" className="form-control wide100" ref={nameRef} readOnly={editableFields} defaultValue={selectedUserInfo.name} required/>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Surname:</div>
                                      <input type="text" className="form-control wide100" ref={surnameRef} readOnly={editableFields} defaultValue={selectedUserInfo.surname} required/>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Email:</div>
                                      <input type="text" className="form-control wide100" ref={emailRef} defaultValue={selectedUserInfo.email} readOnly={true}/>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Roles:</div>
                                      {templateRolesOption(selectedUserInfo.role)}
                                      
                                      
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Phone Number:</div>
                                      <input type="text" className="form-control wide100" ref={phonenumberRef} defaultValue={selectedUserInfo.phonenumber} readOnly={editableFields}/>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Profession:</div>
                                      <input type="text" className="form-control wide100" ref={professionRef}  readOnly={editableFields} defaultValue={selectedUserInfo.profession}/>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Bio:</div>
                                      <textarea className="form-control wide100 heightf150" ref={bioRef} readOnly={editableFields} defaultValue={selectedUserInfo.bio}></textarea>  
                                </div>
                                
                                <div className="form-group">
                                    {
                                        (editableFields === false) && (
                                            <div className="button-execs">
                                                 <button type="submit" className="btn btn-picky mr-2" disabled={processing}>Update</button>         
                                            </div>
                                        )
                                    }
                                </div>
                            </form>

                           
                        </div>
                        
                     </div>
                     {
                        processing && (
                            <div className="img-source">
                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                            </div>
                        )
                    }
                  </div>
            </Dialog>
    </div>
  )
}

export default DlgUser