import React, { useEffect, useRef, useState } from 'react';
import './login.css';
import axios from "axios";
import * as CONSTANTS from "../CONSTANTS";
import { Link } from 'react-router-dom';

function SignUp() {
  
    const [isFetching, setIsFetching]                        = useState(false);
    const [msgCopy, setMsgCopy]                              = useState("");
    const [msgClass, setMsgClass]                            = useState("");
    const [checkToggle, setCheckToggle]                      = useState(false);
    const [checkClass, setCheckClass]                        = useState("check"+checkToggle);
    const [mySuppliers, setMySuppliers]                      = useState([]);

    const nameRef                       = useRef();
    const surNameRef                    = useRef();
    const emailRef                      = useRef();
    const passwordRef                   = useRef();
    const supplierSelectRef             = useRef();

    
    useEffect(()=> {
        fetchMySupplierList();
    },[])
    const fetchMySupplierList = async () => {
      try{
        const response = await axios.get(CONSTANTS.API_URL+"auth/supplies");

          //console.log(response.data);
          setMySuppliers(response.data);
          //console.log("*********")
      }catch(errs){
          console.log("Photo get List of Suppliers");
          console.log(errs);
      }
    }
    const handleRegister = async (e) => {
        e.preventDefault();
        if(nameRef.current.value.length > 1 && emailRef.current.value.length > 3 && passwordRef.current.value.length > 4 ){
          setIsFetching(true);
          let user  = {}

            try{
              if(checkToggle){
                //SIGN UP SUPPLIER
                  user = {
                    "name" : nameRef.current.value,
                    "surname" : surNameRef.current.value,
                    "email" : emailRef.current.value,
                    "password" : passwordRef.current.value,
                    "isAdmin" : false,
                    "profilePic" : "-",
                    "roles" : "supplier",
                    "bio" : "",
                    "phonenumber" : "",
                    "profession" : "",
                    "extras": [],
                    "approve" : false,
                  }
              }else {
                //SIGN UP STANDARD USER
                user = {
                  "name" : nameRef.current.value,
                  "surname" : surNameRef.current.value,
                  "email" : emailRef.current.value,
                  "password" : passwordRef.current.value,
                  "isAdmin" : false,
                  "profilePic" : "-",
                  "roles" : "standard",
                  "bio" : "",
                  "phonenumber" : "",
                  "profession" : "",
                  "extras": [],
                  "approve" : false,
                 }
                           
              }
              
              const response = await axios.post(CONSTANTS.API_URL+"auth/register", user);
              console.log(response.data); 
              if(checkToggle){
                let textSupplierName = supplierSelectRef.current.options[supplierSelectRef.current.selectedIndex].text
                //add user to Supplier
                var obSub = {
                  "id" : response.data._id,
                  "supid" : supplierSelectRef.current.value,
                  "supname": textSupplierName
                }
                //console.log(obSub);

                const addSup = await axios.put(CONSTANTS.API_URL+"auth/addusertosup", obSub);
                //console.log(addSup.data);
              }
              setIsFetching(false);
              setMsgCopy("Registration was successfull please login");
              setMsgClass("alert-success");
              resetFields();   
            }catch(err){
              console.log(err.status);
              setMsgCopy("");
              setIsFetching(false);
            }

        }else {
          setMsgClass("alert-danger");
          setMsgCopy("Please ensure that all fields are filled in correctly");
          setIsFetching(false);
        }
      
      };

      const resetFields = () => {
        nameRef.current.value     = "";
        surNameRef.current.value  = "";
        emailRef.current.value    = "";
        passwordRef.current.value = "";      
      }

      const clickedSwithToggler = (event) =>{
        setCheckToggle(event.target.checked);
        setCheckClass("check"+event.target.checked)
      }
     
    return (
      <div className="logo-base flexlog">
          <div className="log-start">
            <div className={"lgin-spot sign-block" + checkToggle}>
              <h3>Sign Up</h3>
              <form encType="multipart/form-data">
                <div className="form-group frg">
                  <div className="text-form">Name*: </div>
                  <input type="text" className="form-control ct-content wide100" ref={nameRef}  placeholder="Enter Name" required/>
                </div>
                <div className="form-group frg">
                  <div className="text-form">Surname: </div>
                  <input type="text" className="form-control ct-content wide100" ref={surNameRef}  placeholder="Enter Surname"/>
                </div>
                <div className="form-group frg">
                  <div className="text-form">Email*: </div>
                  <input type="email" className="form-control ct-content wide100" ref={emailRef}  placeholder="Enter Email" required/>
                </div>
                <div className="form-group frg">
                  <div className="text-form">Password*: </div>
                  <input type="password" className="form-control ct-content wide100" ref={passwordRef} placeholder="Enter Password" required/>
                </div>
                <div className="form-group frg">
                    <label className="switch-container">
                       <input type="checkbox" className="pos-check" onChange={(event) => clickedSwithToggler(event)} />
                       <p>Are you a supplier: {checkToggle ? "Yes" : "No"}</p>
                       <span className={"slider-switch " + checkClass}></span>
                       
                    </label>

                    {
                      checkToggle ?
                      <div className="supplier-info-log pdtop10">
                          Please select the supplier you belong to?
                          {
                              mySuppliers && (
                                <select className="form-control" ref={supplierSelectRef}>
                                    {mySuppliers.map((supRef) => {
                                       return (<option key={supRef._id} className="opt" value={supRef._id}>{supRef.title}</option>)
                                    })}
                                </select>
                              )
                            }
                           <div className="add-own-sup">
                              <p><span className="small-error-text">If your supplier does not exist, please contact us for registratioin: <br/>
                              <a href="traffic@cjmarketing.co">traffic@cjmarketing.co</a> / <a href="tel:013 010 0091">013 010 0091</a></span></p>                             
                           </div>
                      </div>
                      :
                      <div className="sup-stand pdtop10">
                        <p>Standard User</p>
                      </div>
                    }
                </div>
                <div className="form-group frg">
                   <button className="btn btn-redish" onClick={handleRegister} disabled={isFetching}>Sign Up</button>
                </div>
                <div className="frg">
                  <Link to="/login">
                  Sign In.
                  </Link>
                </div>
              </form>
            
              {
                  isFetching && (
                    <div className="img-source">
                      <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                    </div>
                  )
                }
              <div className="frg">
                {msgCopy && (
                    <div className={"alerter " + msgClass}>
                        {msgCopy}
                    </div>
                )}
              </div>
            </div>
          </div>
          <div className="log-image-pot" style={{backgroundImage: `url(${CONSTANTS.LOGIN_BACKGROUND})` }}>        
                   
          </div>
      </div>
    )
}

export default SignUp