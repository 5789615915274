import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";
import { Button } from 'primereact/button';
import DlgAddPipeline from '../components/DlgAddPipeline';
import DlgAddPipelineItem from '../components/DlgAddPipelineItem';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';

function Pipeline() {
    const [activeIndex, setActiveIndex]               = useState(1);
    const [listPipelines, setListPipelines]           = useState([]);
    const [ackermansData, setAckermansData]           = useState([]);
    const [savePics, setSavedPics]                    = useState([]);
    const [totAckerProdu, setTotAckerProdu]           = useState(0);
    const [picListingItem, setPicListingItem]         = useState([]);
    const [globalFilter, setGlobalFilter]             = useState('');
    const [selectedPicture, setSelectedPicture]       = useState(null);
    const [picSearched, setPicSearched]               = useState(false);

    const [pipeProcStart, setPipeProcStart]           = useState(false);
    const [pProcCatalogue, setPProcCatalogue]         = useState(false);
    const [picProccesing, setPicProccesing]           = useState(false);

    const [showPipelineForm, setShowPipelineForm]      = useState(false);
    const { user }                             = useContext(AuthContext);

    useEffect(()=> {
        getPipes();
    },[]);

    useEffect(()=> {
        if(listPipelines.length > 0){
            //getPipeData();
            getPictures();
        }
    },[listPipelines]);

    /////////////////////////////////////////////////////////////////////////////////////
      const headerTable = (
        <div className="table-header flex justify-content-between">
          <h4 className="mx-0 my-1">Pics  </h4>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search Pictures..." />
          </span>
        </div>
      );

      const viewVisibilityTemplate = (rowData) => {
        var avail  = "AVAILABLE";
        if(rowData.ackershow === false ){
          avail = "NOT AVAILABLE";
        }
        return <strong>{avail}</strong>
      }

      const viewLinkTemplate = (rowData) => {
        return <button onClick={() => transferCheckAndUpdate(rowData)} className="btn btn-circle btsm1 mr-2 text-white">View</button> 
       }
     ////////////////////////////////////////////////////////////////////////////////////

    const transferCheckAndUpdate = async (selected) => {
        try{
          console.log("This is selected");
          console.log(selected);

          const res = await axios.get(CONSTANTS.API_URL +"catalogues/ackerbacode/" + selected.barcode, {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });

            console.log("FEEEDBACK");
            console.log(res.data);
            if(res.data.ackermens_single !== null){

              //check if its true meaning its not invisble
              if(selected.ackershow === false){
    
                 //PICNIC START
                 var picUpdate = {
                  "id": selected._id,
                  "ackershow": true
                }
                
                var dataPICNIC = JSON.stringify(picUpdate);
                /////              
                var configPicUpdate = {
                    method: 'put',
                    url: CONSTANTS.API_URL  + 'pictures/updatepic',
                    headers: { 
                        'token': "Bearer "+ user.accessToken,
                        'Content-Type': 'application/json'
                    },
                    data : dataPICNIC
                };
                let execPic = await axios(configPicUpdate);
                console.log(execPic.data)
                //PICNIC END

                //set LETSTRADE TO FALSE
                //LETSTRADE START
                const resPicsTradeBarcode = await axios.get(CONSTANTS.LETSTRADE1+'product/products?page=1&barcode=' + selected.barcode, {
                    headers: {               
                            'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN,
                        }
                    });

                    var product_id = resPicsTradeBarcode.data.content.product_list[0]._id;
                    
                    var tradeUpdate = {
                      "id": product_id,
                      "status": "AVAILABLE"
                    }

                      //-->
                      var dataTrade = JSON.stringify(tradeUpdate);
                      /////              
                      var configUpdate = {
                          method: 'put',
                          url: CONSTANTS.LETSTRADE1 + 'product/update_product',
                          headers: { 
                              'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN, 
                              'Content-Type': 'application/json'
                          },
                          data : dataTrade
                      };
                      let execProd = await axios(configUpdate);

                //LETSTRADE END
              }
            }else{
              //if its not found 
              //see if its already false
                if(selected.ackershow === true){
                  //Make picnic false
                  //PICNIC START
                  var picUpdate = {
                    "id": selected._id,
                    "ackershow": false
                  }
                  
                  var dataPICNIC = JSON.stringify(picUpdate);
                  /////              
                  var configPicUpdate = {
                      method: 'put',
                      url: CONSTANTS.API_URL  + 'pictures/updatepic',
                      headers: { 
                          'token': "Bearer "+ user.accessToken,
                          'Content-Type': 'application/json'
                      },
                      data : dataPICNIC
                  };
                  let execPic = await axios(configPicUpdate);
                  console.log(execPic.data)
                  //PICNIC END

                  //set LETSTRADE TO FALSE
                  //LETSTRADE START
                  const resPicsTradeBarcode = await axios.get(CONSTANTS.LETSTRADE1+'product/products?page=1&barcode=' + selected.barcode, {
                      headers: {               
                              'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN,
                          }
                      });

                      var product_id = resPicsTradeBarcode.data.content.product_list[0]._id;
                      
                      var tradeUpdate = {
                        "id": product_id,
                        "status": "NOT AVAILABLE"
                      }

                        //-->
                        var dataTrade = JSON.stringify(tradeUpdate);
                        /////              
                        var configUpdate = {
                            method: 'put',
                            url: CONSTANTS.LETSTRADE1 + 'product/update_product',
                            headers: { 
                                'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN, 
                                'Content-Type': 'application/json'
                            },
                            data : dataTrade
                        };
                        let execProd = await axios(configUpdate);

                  //LETSTRADE END
                }
            }
        }catch(ers){
          console.log(ers);
        }
    }

    const getPipes = async () => {
        setPipeProcStart(true);
        try {
            var config = {
                method: 'get',
                url: CONSTANTS.API_URL + "catalogues/pipelines",
                headers: { 
                    token: "Bearer "+ user.accessToken
                }
              };
            const res = await axios(config);
            //console.log(res);
            console.log(res.data);      
            setListPipelines(res.data)
            setPipeProcStart(false);
            //console.log("+++++++++++++++++++++++++++");
          } catch (err) {
            setPipeProcStart(false);
            console.log(err);
            console.log("ERRRRRRR")
          }
    }

    const getPipeData = async () => {
  
        setPProcCatalogue(true);
       try {
            console.log("Coaching... Pipe")
            //console.log(CONSTANTS.API_URL +"catalogues/pipearcker")

            var config = {
                method: 'get',
                url: CONSTANTS.API_URL + "catalogues/pipearcker",
                headers: { 
                    token: "Bearer "+ user.accessToken
                }
              };
            const res = await axios(config);
            //console.log(res);
            console.log("+++++++++++++++++++++++++++");
            console.log(res.data);      
            setAckermansData(res.data);   
            setTotAckerProdu(res.data.length);
            setPProcCatalogue(false);   
            //console.log("+++++++++++++++++++++++++++");
          } catch (err) {
            setPProcCatalogue(false);   
            console.log(err);
            console.log("ERRRRRRR")
          }
         
    }


    const getPictures = async () => {
        try {
            //console.log("Coaching...")
            setPicProccesing(true);
            ///console.log(CONSTANTS.API_URL +"catalogues/pipepics")

            var config = {
                method: 'get',
                url: CONSTANTS.API_URL + "pictures/assembly",
                    headers: { 
                        token: "Bearer "+ user.accessToken
                }
              };
            const res = await axios(config);
            //console.log(res);
            console.log("#########################");
            console.log(res.data);      
            setPicListingItem(res.data);      
            setPicProccesing(false);             
            //console.log("#########################");
          
          } catch (err) {
            setPicProccesing(false);
            console.log(err);
            console.log("ERRRRRRR")
          }
    }


  return (
    <div className="main-standard">
        <h2 className="lbl-title-page">Pipeline</h2>
        <div className="card">
            <div className="card-body">
             
             <div className="pdtb10">
                <Button
                        className="btn btn-circle btsm1 mr-2"
                        label="Create Pipe"
                        onClick={() => setShowPipelineForm(true)}
                        />
             </div>
            
        
        <DlgAddPipelineItem 
            showPipelineForm={showPipelineForm} 
            setShowPipelineForm={setShowPipelineForm} 
            person={user} />
               
                {
                    listPipelines && (
                        <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        {
                            listPipelines.map((catItem) => {
                                return <AccordionTab header={catItem.title} key={catItem._id}>
                                          <div className="col-boxer">
                                              <div className="tb-log">
                                                {
                                                  picListingItem && (
                                                    <DataTable
                                                        value={picListingItem}
                                                        header={headerTable}
                                                        paginator rows={20} rowsPerPageOptions={[20, 30, 40]}
                                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                                        responsiveLayout="scroll"
                                                        globalFilter={(globalFilter) ? globalFilter : ''}
                                                        selection={selectedPicture}
                                                        onSelectionChange={e => setSelectedPicture(e.value)}>

                                                              <Column field="title" header="Title"></Column>  
                                                              <Column field="barcode" header="Barcode"></Column>
                                                              <Column field="onlineDesc" header="Online Description"></Column>   
                                                              <Column header="Visibility" body={viewVisibilityTemplate}></Column>    
                                                              <Column header="View" body={viewLinkTemplate}></Column>    
                                                               
                                                    </DataTable>
                                                  )
                                                }
                                              
                                              </div>
                                          </div>
                                       </AccordionTab>
                            }) 
                         }
                         </Accordion>
                    )
                }
               
            </div>
        </div>
    </div>
  )
}

export default Pipeline