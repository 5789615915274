import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../CONSTANTS";
import * as XLSX from 'xlsx';
import axios from "axios";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faUpload, faFileExcel} from '@fortawesome/free-solid-svg-icons';

function DlgImportTAssign(props) {

    const mainBarcodesRef                                       = useRef("");
    const [msgBarcodes, setMsgBarcodes]                         = useState("");
    const [msgBarClass, setMsgBarClass]                         = useState("");

    async function handleExcelUpload(e) {
        e.preventDefault();
        console.log("Cata " + props.catid);
        const f = mainBarcodesRef.current.files[0];
        const data = await f.arrayBuffer();
        
        var workbook = XLSX.read(data);  
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const dataJson  = XLSX.utils.sheet_to_json(worksheet);
        const finalBarcodes  =  [];
        let stringBarcodes = "";
         //console.log(dataJson);
         //console.log("________")
        //console.log(dataJson[0]);
        if ('barcodes' in dataJson[0]){
            setMsgBarcodes("");
            setMsgBarClass("");

            const barcodesCat = {
                "catalogueId" :  props.catid,
                "data":dataJson
             }
             console.log(barcodesCat);
            ///////////////////////
            try{
                const res = await axios.put(CONSTANTS.API_URL +"catalogues/assignexcel/"+props.catid, {
                    dataJson
                }, {
                    headers: {
                        token: "Bearer "+ props.person.accessToken
                    }
                });
                console.log(res);
                console.log(res.data);
                //props.setDisplayTokenDialog(false);
                setMsgBarcodes("Barcodes used to update the catalogue successfully.");
                setMsgBarClass("alert-success");
            }
            catch(errors){
                console.log("Create TOKEN ERROR");
                console.log(errors)
            }
           ///////////////////////
        }else {
            console.log("We not good");
            setMsgBarcodes("The excel sheet should only carry one colume with the tiltle barcodes along with the list of barcodes below it.");
            setMsgBarClass("alert-warning");
        }
        
     
    }

  return (
        <Dialog header="Upload Excel Document" visible={props.dsplyImprtAsignDialog} style={{ width: '50vw' }} modal={true} onHide={() => props.setDsplyImprtAsignDialog(false)}
        footer={
            <div>
                <Button className="btn btn-picky" label="Close" onClick={() => props.setDsplyImprtAsignDialog(false)} />
            </div>
        }>
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-12">

                <form onSubmit={handleExcelUpload}>
                    <div className="input-block-group">                                         
                        <div className="ico-title ico-tt-green mgbot20"><FontAwesomeIcon icon={faFileExcel} /></div>
                        <input type="file" ref={mainBarcodesRef} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" className="form-control" required/>
                    </div>                                      

                    <div className="button-row">
                        <button className="btn btn-picky" >  <FontAwesomeIcon icon={faUpload} /> Import</button>  
                    </div>  
                
                </form>

                {
                    msgBarcodes && (
                        <div className={"alert-area " + msgBarClass}>
                            {msgBarcodes}
                        </div>
                    )
                }
            </div>
        </div>


    </Dialog>
  )
}

export default DlgImportTAssign