import React from 'react'

function AccessDenied() {
  return (
    <div className="main-standard">
        <h2 className="lbl-title-page">Access Denied</h2>
        <div className="card">
                <div className="card-body text-center">
                        <img src="https://cjmarketing.co/image_directory/eish.png"  className="access-image"/>
                        <div className="bigger-text">
                            You do not have access to this page. 
                        </div>
                </div>
        </div>
    </div>
  )
}

export default AccessDenied