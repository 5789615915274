import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext';
import * as CONSTANTS from "../CONSTANTS";
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


function PhotoList() {
    const [pictureList, setPictureList]                  = useState([]);
    const [picCount, setPicCount]                        = useState(0);
    const [globalFilter, setGlobalFilter]                = useState('');

    const [processing, setProcessing]              = useState(false);

    const navigate = useNavigate()

    const { user } = useContext(AuthContext);

    useEffect(() => {

      if(!user.isAdmin){
        navigate("/access-denied");
      }        
      getPhotos();
    }, [])

    
  /////////////////////////////////////////////////////////////////////////////////////
   const headerProductTable = (
      <div className="table-header flex justify-content-between">
        <h4 className="mx-0 my-1">Pictures  ({picCount})</h4>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search Pictures..." />
        </span>
      </div>
    );
      /////////////////////////////////////////////////////////////////////////////////////
      const imageProductTemplate = (rowData) => {     
      return <div className="img--box"><Link to={"/photo/" + rowData._id} className="link-no-styling"><img src={rowData.imgurl} alt="image" className="box-image"  /></Link></div>;
    }
    /////////////////////////////////////////////////////////////////////////////////////
    const viewPhotoTemplate = (rowData) => {
      return <Link to={"/photo/" + rowData._id} className="btn btn-picky btsm1">View</Link> 
    }
    /////////////////////////////////////////////////////////////////////////////////////
    const viewOnlineShortDesccriptionTemplate = (rowData) => {
      return (rowData.onlineDesc.length > 160 ? <>{rowData.onlineDesc.substring(0, 160)} ...</> : <>{rowData.onlineDesc}</>)
    }
    /////////////////////////////////////////////////////////////////////////////////////
    const getPhotos = async () => {
       // console.log("LIbrary")
       // console.log(user);
       setProcessing(true);
        try {
            //console.log("Coaching...")
            //console.log(CONSTANTS.API_URL+"pictures/free?page="+page+"&limit="+plimit)
            const res = await axios.get(CONSTANTS.API_URL +"pictures/assembly", {
                  headers: {
                      token: "Bearer "+ user.accessToken
                  }
              });
            //console.log(res);
            //console.log(res.data);
            setPictureList(res.data);
            setPicCount(res.data.length);
            //console.log("+++++++++++++++++++++++++++");
            setProcessing(false);
          } catch (err) {
            console.log(err);
            console.log("ERRRRRRR");
            setProcessing(false);
            if(err.message === "Request failed with status code 403"){
              localStorage.removeItem("user_picknic2");
              document.location.href="/login";
             }
          }
        
    }

   

  return (
    <div className="main-standard">
      <h2 className="lbl-title-page">Library</h2>
        <div className="card">

        <div className="card-body">
                <div className="row ">
                {
                    pictureList && (
                    
                      <DataTable
                        value={pictureList}
                        header={headerProductTable}
                        paginator rows={50} rowsPerPageOptions={[50, 80, 100]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        responsiveLayout="scroll"
                        globalFilter={(globalFilter) ? globalFilter : ''}
                        >
                        <Column header="IMAGE" body={imageProductTemplate}></Column>     
                        <Column field="barcode" header="Barcode"></Column>    
                        <Column field="title" header="Title"></Column>     
                        <Column header="Sub Description" body={viewOnlineShortDesccriptionTemplate}></Column>
                        <Column field="brand" header="Brands"></Column>    
                        <Column field="price" header="Price"></Column>                       
                        <Column header="View" body={viewPhotoTemplate}></Column>                    
                    </DataTable>
                    )
                }
                </div>

              </div>
              {
                  processing && (
                      <div className="img-source">
                      <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc profixed" />
                      </div>
                  )
                }

      </div>
    </div>  
  )
}

export default PhotoList