import React, { useRef, useState }  from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";

function DlgSupplierApprovalCode({showApprovalCode, setShowApprovalCode, appcode, setApprovalCode, supplierId, members}) {
    const supplierCode      = useRef();

    const makeCodeSection = async () => {
      const passWord = makeid(6);

      var updateCode = {
        "code": passWord,
        "supplierid": supplierId
      }
      //console.log(updateCode);
        try{
          const rCode = await axios.put(CONSTANTS.API_URL+"suppliers/aprovecode/" ,updateCode, {
                headers: {
                    token: "Bearer "+ members.accessToken
                }
            });
            //console.log(rCode.data);
            setApprovalCode(rCode.data.supplier.approvalCode);
        }catch(ergen){
          console.log(ergen);
        }
    }

    function makeid(length) {
          var result           = '';
          var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          var charactersLength = characters.length;

          for ( var i = 0; i < length; i++ ) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
          }
      return result;
    }

  return (
    <div className="block-comp">
         <Dialog header="Approval Code" visible={showApprovalCode} onHide={() => setShowApprovalCode(false)} style={{ width: '50vw' }}
            footer={
            <div>
                {/* link button */}
              
                <Button className="btn btn-picky" label="Close" onClick={() => setShowApprovalCode(false)} />
            </div>
            }>
            <div className="modal-body-content">
              
                <div className="content-box">
                The approval code is currently:
                  {appcode ? (
                        <div className="special-size">
                          {appcode}
                        </div>
                      )
                    :
                    (
                      <div className="">
                        -
                      </div>
                    )
                  }

                   <div className="gray-section mgtop20">
                      <button className="btn btn-picky btsm1" onClick={() => makeCodeSection()}>GENERATE NEW CODE</button>
                   </div>
                </div>
            </div>
        </Dialog>
    </div>
  )
}

export default DlgSupplierApprovalCode