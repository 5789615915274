import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";

function DlgAddCategory(props) {


  const [addSub, setAddSub]                   = useState(false);
  const [addTitle, setAddTitle]               = useState("");
  const [msgClass, setMsgClass]               = useState("");
  const [msgFeed, setMsgFeed]                 = useState("");
  const [proc, setProc]                       = useState(false);
  const [checkedTrade, setCheckedTrade]       = useState(false);

  const categoryTitleRef                      = useRef();
  const categoryTextAreaRef                   = useRef();

  useEffect(() => {    
    if(props.currentCat){
      console.log("WE have a sub");
      setAddSub(true);
      setAddTitle("Add Sub Category of " + props.currentCat.categoryName)
    }else {
      setAddTitle("Add Category")
      setAddSub(false);
    }
  },[props.currentCat]);

  async function handleFormCategoryAdd(e) {
    e.preventDefault();  
    setProc(true);
    try{
      
      console.log(addSub);
      console.log(checkedTrade);

    //[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[
    //If it has a letstrade check then it will create the category first on letstrade then copy the data to picnic
    if(checkedTrade){
  
            //????
            if(addSub){
                
                var tradeSubCat = {
                  "name": categoryTitleRef.current.value,
                  "description": categoryTextAreaRef.current.value,
                  "image_url": "",
                  "image_list": []
                }
                console.log("Try to add sub category");
                console.log(props.currentCat);

                let subCatsOptions = [];
          
                subCatsOptions.push(tradeSubCat);

                let catupdate = {       
                  "id": props.currentCat.catId,
                  "name": props.currentCat.categoryName ,
                  "sub_categories": subCatsOptions
                
                }

                console.log("#################  consolllle START");
                console.log(catupdate);
                console.log("#################  consolllle END");

                /////=== LETS TRADE START
                var catupdateString = JSON.stringify(catupdate);

                var config = {
                  method: 'put',
                  url: CONSTANTS.LETSTRADE1+'product/update_category',
                  headers: {
                    'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN,
                    'Content-Type': 'application/json'
                  },
                  data : catupdateString
                };
                
                
                const repCateSubTrade = await axios(config);
              
                console.log(repCateSubTrade);
                console.log(repCateSubTrade.data);
                console.log(repCateSubTrade.data.content.sub_categories);
                /////=== LETS TRADE   END
                //update category on the picnic end
              
                //PICNIC START
                    if(repCateSubTrade.data.success){
                      
                      let picCateSub = {
                        "id" : props.currentCat._id,
                        "categoryName" : props.currentCat.categoryName,
                        "sub_categories" :repCateSubTrade.data.content.sub_categories
                      }
                      //make picnic post request
                      //picCateSub
                      console.log("^&^&^&^&^&^&^&^&^&^&^&^&^Y^");
                      console.log(picCateSub);
                      const picCatsRep = await axios.put(CONSTANTS.API_URL+"categories/updatesubcat", picCateSub, {
                                headers: {
                                    token: "Bearer "+ props.person.accessToken
                                }
                          });

                        console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^");
                        console.log(picCatsRep.data);
                        if(picCatsRep.data.status == "success"){
                          setMsgClass("alert-success")
                          setMsgFeed("Update was succefull");
                        }else{
                          setMsgClass("alert-danger")
                          setMsgFeed("Could not update category, Please try again later");
                        }
                        setProc(false);
                        
                        props.setCatAdded(props.catadded + 1);
                    }else {
                      setMsgClass("alert-danger")
                      setMsgFeed("Could not update category, Please try again later");
                    }
                
                //PICNIC END
              
            }else {
            //????  
            //LETSTRADE1
            

              var letsTradeCat = {
                "name": categoryTitleRef.current.value,
                "description": categoryTextAreaRef.current.value,
                "image_url": "",
              }
                
              setMsgClass("alert-success");
              setMsgFeed("Category added Succefully");
              //console.log(repCategory.data);
              

              /////=== LETS TRADE
              const repCateTrade = await axios.post(CONSTANTS.LETSTRADE1+"product/add_category", letsTradeCat, {
                  headers: {
                    'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN,
                    'Content-Type': 'application/json'
                  }
              });
              console.log("LETS TRADE");
              console.log(repCateTrade);
              console.log(repCateTrade.data);
              if(repCateTrade.data.success){

                const cateGory = {
                      "catId": repCateTrade.data.content._id,
                      "categoryName":categoryTitleRef.current.value,
                      "categoryDescription": categoryTextAreaRef.current.value,
                      "sub_categories": [],
                      "tradeshare" : true,
                      "extra_fields": [],
                      "slayer": false,
                  }
                  
                  const repCategory = await axios.post(CONSTANTS.API_URL+"categories/", cateGory, {
                      headers: {
                          token: "Bearer "+ props.person.accessToken
                      }
                  });

                  console.log("Cat Picnic Data")
                  console.log(repCategory.data);
                  categoryTitleRef.current.value = "";
                  categoryTextAreaRef.current.value = "";
                  setMsgClass("alert-success");
                  setMsgFeed("Category added Succefully");
                  props.setCatAdded(props.catadded + 1);
                  
              }else {
                setMsgClass("");
                setMsgFeed("");
              }
              setProc(false);
            }
      //]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]
    }else {
      //[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[
        if(addSub){
            //???????
            const newCodeId = makeid(14);
            let subCattyPic = {
              "id" : newCodeId, 
              "name": categoryTitleRef.current.value,
              "description": categoryTextAreaRef.current.value,
              "image_url" : ""
            }
            console.log("Cat ID to use");
            console.log(props.currentCat._id);
            let picCateSub = {
              "id" : props.currentCat._id,
              "categoryName" : props.currentCat.categoryName,
              "sub_categories" :[subCattyPic]       
            }

            console.log(picCateSub);
            const picCatsRep = await axios.put(CONSTANTS.API_URL+"categories/updatesubcat", picCateSub, {
                      headers: {
                          token: "Bearer "+ props.person.accessToken
                      }
                });

              console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^");
              console.log(picCatsRep.data);
              if(picCatsRep.data.status == "success"){
                setMsgClass("alert-success")
                setMsgFeed("Update was succefull");
              }else{
                setMsgClass("alert-danger")
                setMsgFeed("Could not update category, Please try again later");
              }
              setProc(false);

            //???????
        }else {
            var picNicCat = {
              "catId": Math.floor(Math.random() * 10000000) + 1,
              "categoryName": categoryTitleRef.current.value,
              "categoryDescription": categoryTextAreaRef.current.value,
              "image_url": "",
              "sub_categories": [],
              "tradeshare" : false,
              "extra_fields": [],
              "slayer": false,
            }

            console.log(picNicCat);
            console.log(CONSTANTS.API_URL);
            var dataCat = JSON.stringify(picNicCat);

            var config = {
              method: 'post',
              url: CONSTANTS.API_URL+"categories",
              headers: {
                'token': 'Bearer '+ props.person.accessToken,
                'Content-Type': 'application/json'
              },
              data : dataCat
            };
            
           
            const repCategory = await axios(config);

            console.log(repCategory.data);
          
         
            setMsgClass("alert-success");
            setMsgFeed("Category added Succefully");
            props.setCatAdded(props.catadded + 1);
            setProc(false);
            //categoryTitleRef.current.value = "";
            //categoryTextAreaRef.current.value = "";
        }
      //]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]
    }



    }catch(erForm){
      setProc(false);
      console.log(erForm)
      setMsgClass("alert-danger")
      setMsgFeed("Something went wrong, Please try again later");
    }
  }

  const handleChange = () => {
    setCheckedTrade(!checkedTrade);
  };

  function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  return (
    <Dialog header={addTitle} visible={props.dsplyCategoryDialog} style={{ width: '50vw' }} modal={true} onHide={() => props.setDsplyCategoryDialog(false)}
      footer={
          <div>
              <Button className="btn btn-picky" label="Close" onClick={() => props.setDsplyCategoryDialog(false)} />
          </div>
      }>
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-12">
              <div className="">
                {msgFeed && (
                  <div className={"alert " + msgClass}>
                    {msgFeed}
                  </div>
                )}
              </div>
                <form  onSubmit={handleFormCategoryAdd}>
                     <div className="form-group">
                        <div className="lbldesc">Category Title*: </div>
                        <input type="text" ref={categoryTitleRef} className="form-control wfull" required/>
                     </div>
                     <div className="form-group">
                        <div className="lbldesc">Category Information: </div>
                        <textarea ref={categoryTextAreaRef} className="form-control wfull h100f"></textarea>                        
                     </div>
                     <div className="form-group">
                       <input
                          type="checkbox"
                          checked={checkedTrade}
                          onChange={handleChange}
                        /> Letstrade Share<br /><br />
                     </div>
                     <div className="form-group">
                         <button type="submit" className="btn btn-picky" disabled={proc}>Create Category</button>
                     </div>
                </form>
                {
                    proc && (
                        <div className="img-source">
                           <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                        </div>
                    )
                }
            </div>
        </div>
    </Dialog>
  )
}

export default DlgAddCategory