import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faCartFlatbedSuitcase } from '@fortawesome/free-solid-svg-icons';
import './home.css';

function CUSSupplierItems({user}) {

    const [processing, setProcessing]               = useState(false);
    const [supplierItems, setSupplierItems]         = useState([]);
    const [prodList, setProdList]                   = useState([]);
    const [totalPics, setTotalPics]                 = useState([]);
    const [totalPicsVis, setTotalPicsVis]           = useState([]);
    const [supplierFound, setSupplierFound]         = useState(false);
    

    useEffect(()=> {
        getMySupplier();
    },[]);

    useEffect(()=> {
        if(supplierItems.length > 0){
            getSupplierPicturesInfo();
        }
    }, [supplierItems])

    const getMySupplier = async () => {
        setProcessing(true);
        try{
            const res = await axios.get(CONSTANTS.API_URL +"suppliers/list", {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
            console.log(res.data);

            if(res.data.length !== 0){
                setSupplierFound(true);
            }
           setSupplierItems(res.data);
           setProcessing(false);
        }catch(errs){
            setProcessing(true);
            console.log(errs)
        }
    }

    const getSupplierPicturesInfo = async () => {
        try{

            const respPics = await axios.get(CONSTANTS.API_URL+"suppliers/summon/"+ supplierItems[0]._id, {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
    
            setProdList(respPics.data.Pictures);
            setTotalPics(respPics.data.Pictures.length);
            let visibles = 0;
            for (let i = 0; i < respPics.data.Pictures.length; i++) {
                 if(respPics.data.Pictures[i].draftmode === false){
                    visibles++;
                 }
            }
            setTotalPicsVis(visibles);
        }catch(errs){
            console.log(errs);

        }
    }
  return (
    <div className="col-md-6">
        <div className="card flex-mg-space ">
                <div className="card-body widget-body">
                    <div className="home-dash-item">
                        <div className="ico-title ico-tt-blue">
                        <FontAwesomeIcon icon={faCartFlatbedSuitcase} /> 
                        </div>
                      
                        <h3 className="pd5">Supplier Details 
                           {
                            supplierFound && (<span> <strong>{supplierItems[0].title}</strong></span>)
                           }
                        </h3>
                        
                        <div className="flexme pd5">
                            <div className="fl-itdetails wide50">
                                {
                                    totalPics && (
                                      <>
                                         <div className="am-Number">{totalPics}</div>
                                         <div className="sub-text-inf">Total Pictures</div>
                                      </>
                                    )
                                }
                                
                            </div>
                            <div className="fl-itdetails">
                                {
                                    totalPicsVis && (
                                        <>
                                         <div className="am-Number">{totalPicsVis}</div>
                                         <div className="sub-text-inf">Total Visible</div>
                                        </>
                                    )
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>

                {
                    processing && (
                        <div className="img-source">
                           <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                        </div>
                    )
                }
            </div>
    </div>
  )
}

export default CUSSupplierItems