import React, { useContext, useEffect, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';

function Brand() {
    const [processing, setProcessing]                  = useState(false);
    const [prodList, setProdList]                      = useState([]);
    
    const { user }                                     = useContext(AuthContext);

    useEffect(() => {
        getThisBrandsDetails()
    },[]);

    const getThisBrandsDetails = async () => {
        try{
            const supplierShort = user.extras[0];
          
            const repSupp = await axios.get(CONSTANTS.API_URL+"suppliers/summon/"+  supplierShort.supplierId, {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
            //
            //console.log(repSupp);
            console.log(repSupp.data.Pictures);
            setProdList(repSupp.data.Pictures);
        }catch(err){
            console.log(err);

        }
    }
  return (
    <div className="main-standard">
        <h2 className="lbl-title-page">Brand Detials</h2>
           <div className="card">
           {prodList  && 
              (
           
                  <div className="card-body">
                      <div className="row">

                      {
                        prodList.map((pro) => {
                          return <div className="col-md-3" key={pro._id}>
                                    <div className="pr-image">
                                        <Link to={"/photo/" + pro._id} className="lk-dash">
                                          {pro.barcode}
                                        </Link>
                                        <div className="">
                                          <Link to={"/photo/" + pro._id} className="lk-dash">
                                            <img src={pro.imgurl} className="img-responsive fl-image" />
                                          </Link>
                                        </div>
                                    </div>
                                </div>
                        })
                      }
                      </div>
                  </div>
              
              )
           }
           </div>
    </div>
  )
}

export default Brand