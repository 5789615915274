import React, { useContext, useEffect, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

function Suppliers() {
  const { user }                                       = useContext(AuthContext);
  const [supplierItems, setSupplierItems]              = useState([]);
  const [selectedSupplier, setSelectedSupplier]        = useState(null);
  const [globalFilter, setGlobalFilter]                = useState('');
  const [classList, setClassList]                      = useState("col-md-12");
  const [processing, setProcessing]                    = useState(false);
  const [supCount, setSupCount]                        = useState(0);
  
  useEffect(() => {
      getAllSuppliers();

      if(user.isAdmin){
        setClassList("col-md-4");
      }
  },[])

  /////////////////////////////////////////////////////////////////////////////////////
  const headerTable = (
    <div className="table-header flex justify-content-between">
      <h4 className="mx-0 my-1">Supplier  ({supCount})</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search Pictures..." />
      </span>
    </div>
  );
  
  /////////////////////////////////////////////////////////////////////////////////////
  const getAllSuppliers = async () => {
      setProcessing(true);
      try {

        const res = await axios.get(CONSTANTS.API_URL +"suppliers/list", {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

        setSupplierItems(res.data);
        setSupCount(res.data.length)
        setProcessing(false);
        //console.log("+++++++++++++++++++++++++++");
      } catch (err) {
        setProcessing(false);
        console.log(err);
        console.log(err.message);
      if(err.message === "Request failed with status code 403"){
        localStorage.removeItem("user_picknic2");
        document.location.href="/login";
      }
        console.log("ERRRRRRR")
      }
  }

  const fromSalesLayTemplate = (rowData) => {
    // console.log(rowData);
      if(rowData.slayer === true){
          return <div className="small">True</div>
      }else {
          console.log(rowData.parentCatId);
          return <div className="small">False</div>
      }     
   }
    
   const viewSingleTemplate = (rowData) => {
    return <Link to={"/supplier-details/" + rowData._id} className="btn btn-picky btsm1">View</Link> 
   }
  return (
    <div className="main-standard">
       <h2 className="lbl-title-page">Suppliers</h2>
       <div className="list-supplier">
           <div className="card">
              <div className="card-body">
                <div className="row ">
                {
                    supplierItems && (
                    
                      <DataTable
                        value={supplierItems}
                        header={headerTable}
                        paginator rows={20} rowsPerPageOptions={[20, 30, 40]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        responsiveLayout="scroll"
                        globalFilter={(globalFilter) ? globalFilter : ''}
                        selection={selectedSupplier}
                        onSelectionChange={e => setSelectedSupplier(e.value)}
                        >
                        <Column field="title" header="Title"></Column>     
                        <Column header="SalesLayer" body={fromSalesLayTemplate}></Column>
                        <Column field="information" header="Descr"></Column>
                        <Column field="userIds" header="Users"></Column>    
                        <Column header="View" body={viewSingleTemplate}></Column>                    
                    </DataTable>
                    )
                }
                </div>

              </div>
                   {
                        processing && (
                            <div className="img-source">
                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc profixed" />
                            </div>
                        )
                     }
           </div>

       </div> 
    </div>
  )
}

export default Suppliers