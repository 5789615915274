import React, { useContext, useEffect, useRef, useState } from 'react';
import {useParams} from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import * as CONSTANTS from "../CONSTANTS";
import { Button } from 'primereact/button';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faThumbsUp, faTrash} from '@fortawesome/free-solid-svg-icons';

import axios from "axios";
import DlgEditPhoto from '../components/DlgEditPhoto';
import DlgEditPhotoSupplier from '../components/DlgEditPhotoSupplier';
import DlgEditPhotoCategory from '../components/DlgEditPhotoCategory';
import {useNavigate} from "react-router-dom";

function Photo() {
  const [photo, setPhoto]                                               = useState([]);
  const [processing, setProcessing]                                     = useState(false);

  const { user }                                                        = useContext(AuthContext);
  const params                                                          = useParams();

  const [imageMainpreview, setImagePreview]                             = useState(CONSTANTS.PREVIEW_IMAGE);
  const [unpackpreview, setUnpackPreview]                               = useState(CONSTANTS.PREVIEW_IMAGE);
  const [leftpreview, setLeftPreview]                                   = useState(CONSTANTS.PREVIEW_IMAGE);
  const [rightpreview, setRightPreview]                                 = useState(CONSTANTS.PREVIEW_IMAGE);

  const [thumbsOne, setThumbsOne]                                       = useState(false);
  const [thumbsTwoU, setThumbsTwoU]                                     = useState(false);
  const [thumbsThrL, setThumbsThrL]                                     = useState(false);
  const [thumbsFouR, setThumbsFouR]                                     = useState(false);

  const [delTwoU, setDelTwoU]                                           = useState(false);
  const [delThrL, setDelThrL]                                           = useState(false);
  const [delFouR, setDelFouR]                                           = useState(false);

  const [msMainAlert, setMsgMainAlert]                                  = useState("");
  const [msgUnpackAlert, setMsgUnpageAlert]                             = useState("");
  const [msgLeftAlert, setMsgLeftAlert]                                 = useState("");
  const [msgRightAlert, setMsgRightAlert]                               = useState("");

  const [msUnpackAleDel, setMsgUnpackAleDel]                            = useState("");
  const [msgLeftAleDel, setMsgLeftAleDel]                               = useState("");
  const [msRightAleDel, setMsgRightAleDel]                              = useState("");

  const [showDialPhoto, setShowDialPhoto]                               = useState(false);
  const [showDialPhotoSup, setShowDialPhotoSup]                         = useState(false);
  const [showDialPhotoCategory, setShowDialPhotoCategroy]               = useState(false);
  const [presentList, setPresentation]                                   = useState([]);

  const [updateMe, setUpdateMe]                                         = useState(0);

  const [suppli, setSupplier]                                           = useState({});

  const mainImage             = useRef();
  const unpackedImage         = useRef();
  const rightImage            = useRef();
  const leftImage             = useRef();

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  useEffect(() => {
    getPhoto();
    getListOfPresentations();
  },[updateMe]);


  const getPhoto = async () => {

     try {
       
         const res = await axios.get(CONSTANTS.API_URL +"pictures/photo/"+ params.id, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });
       
         setPhoto(res.data);

         if(res.data.imgUnpack !== ""){
          setDelTwoU(true);
         }
         if(res.data.imgLeft !== ""){
          setDelThrL(true);
         }
         if(res.data.imgRight !== ""){
          setDelFouR(true);
         }
        
      
         getSuppl(res.data);
       } catch (err) {
         console.log(err);
         console.log("ERRRRRRR PHOTO");
         console.log(err.response.data);
         console.log(err.response.status);
         if(err.response.data === "Token is not valid!"){
           localStorage.removeItem("user_picknic2");
           document.location.href="/login";
         }
         
       }
     
 }

 const getListOfPresentations = async () => {
    try{

        const resPresent = await axios.get(CONSTANTS.API_URL+"pictures/presentations", {
            headers: {
                token: "Bearer "+ user.accessToken
            }
          });
         
          setPresentation(resPresent.data);

    }catch(ers){
        console.log(ers);
        console.log("ERR")
    }
  }

 const getSuppl = async (photoItem) => {

    try{
      

      const resSup = await axios.get(CONSTANTS.API_URL +"suppliers/single/"+ photoItem.supplierid, {
          headers: {
              token: "Bearer "+ user.accessToken
          }         
      });
     
      setSupplier(resSup.data.currentSupplier);
    }catch(ers){
      console.log(ers)
    }
 }

  //////////////////////////////////////////////////
  const checkMainImage  = () => {
    if(mainImage.current.files[0]){
    
       setImagePreview(URL.createObjectURL(mainImage.current.files[0]));
    }
  }

  async function handleFormUploadMainImage(e) {
   e.preventDefault(); 
      //replace call
    
      const formData = new FormData();
    
      const resindx = photo.imgurl.indexOf("uploads/");
     
      let resKey = photo.imgurl.substring(resindx + 8);
  
      formData.append("mainpicurlupdate", mainImage.current.files[0]);
      formData.append("picid", photo._id);
      formData.append("deleturl", resKey);

      setProcessing(true);
     
      try{
        const response = await axios.post(CONSTANTS.API_URL+"pictures/mainurlupdate" ,formData, {
            Headers: {
              'Content-Type': 'multipart/form-data',
              token: "Bearer " + user.accessToken
            }
          });

          //console.log(response.data);
          setThumbsOne(true)

        if(response.data.status == "success"){
          /*
          //LETSTRADE START
          const resPicsTradeBarcode = await axios.get(CONSTANTS.LETSTRADE1+'product/products?page=1&barcode=' + photo.barcode, {
            headers: {               
                    'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN,
                }
            });
          
            var product_id = resPicsTradeBarcode.data.content.product_list[0]._id; 
            let newURL = response.data.updatedImage.imgurl;
            //UPDATE PRODUCT
            if(resPicsTradeBarcode.data.success === true){

              var tradeUpdate = {
                "id": product_id,
                "image_url": newURL
              }

              //-->
              var dataTrade = JSON.stringify(tradeUpdate);
                 /////              
                  var configUpdate = {
                      method: 'put',
                      url: CONSTANTS.LETSTRADE1 + 'product/update_product',
                      headers: { 
                          'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN, 
                          'Content-Type': 'application/json'
                      },
                      data : dataTrade
                  };
                  let execProd = await axios(configUpdate);
               
                  console.log(execProd.data);
              //-->
            }
            */
          //LETSTRADE END
          setUpdateMe(1);
          e.target.reset();
        }
        setProcessing(false);
      }catch(err){
        console.log("papalina")
        console.log(err);
        setProcessing(false);
      }
     
  }


//////////////////////////////////////////////////
  const checkUnpackedImage  = () => {
    if(unpackedImage.current.files[0]){
        setUnpackPreview(URL.createObjectURL(unpackedImage.current.files[0]));
    }
  }

  async function handleFormUploadUnpackedImage(e) {
   e.preventDefault(); 

   //image_list UNPACKED : ORDER 1
   let fileNameText = unpackedImage.current.files[0].name.replace(/(.*)\.[^.]+$/, "$1");

   if(fileNameText.indexOf("U_") === 0){
      
      let unpackFileName = fileNameText.substring(2);
   
      if(unpackFileName === photo.barcode){
   
        setMsgUnpageAlert("");
        const formData = new FormData();
          formData.append("sideimageurl", unpackedImage.current.files[0]);
          formData.append("imgtype", "imgUnpack");
          formData.append("picid", photo._id);
        
          setProcessing(true);
          try{
            const responsePicnic = await axios.post(CONSTANTS.API_URL+"pictures/sideimage" ,formData, {
              Headers: {
                'Content-Type': 'multipart/form-data',
                token: "Bearer " + user.accessToken
              }
            });

            //picnic list
           
            if(responsePicnic.data.status == "success"){
              //LETSTRADE START              
              const resPicsTradeBarcode = await axios.get(CONSTANTS.LETSTRADE1+'product/products?page=1&barcode=' + photo.barcode, {
                    headers: {               
                        'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN,
                    }
                });
                //UPDATE PRODUCT
                if(resPicsTradeBarcode.data.success === true){
                   var product_id = resPicsTradeBarcode.data.content.product_list[0]._id;  
                   let newUnpackedURL = responsePicnic.data.updatedImage.imgUnpack;
               
                   
                   let imgeUpacked = [];
                  if(resPicsTradeBarcode.data.content.product_list[0].image_list.length === 0){
                      imgeUpacked = [
                        {
                            "url": newUnpackedURL,
                            "order": 1
                        }];
                  }else {
                    /////-->
                         
                           //The unpack image has to be added to the list
                           let item = {
                            "url": newUnpackedURL,
                            "order": 1
                          }
                          imgeUpacked.push(item);
                                      
                          //Add the left image if it exists
                          if(responsePicnic.data.updatedImage.imgLeft !==""){
                              let item = {
                                "url": responsePicnic.data.updatedImage.imgLeft,
                                "order": 2
                              }
                              imgeUpacked.push(item);
                          }

                          if(responsePicnic.data.updatedImage.imgRight !==""){
                              let item = {
                                "url": responsePicnic.data.updatedImage.imgRight,
                                "order": 3
                              }
                              imgeUpacked.push(item);
                          }
                    /////-->
                  }
                   
                   var tradeUpdate = {
                        "id": product_id,
                        "image_list": imgeUpacked
                    }

                    var dataTrade = JSON.stringify(tradeUpdate);
                            /////
                            
                                var configUpdate = {
                                    method: 'put',
                                    url: CONSTANTS.LETSTRADE1 + 'product/update_product',
                                    headers: { 
                                        'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN, 
                                        'Content-Type': 'application/json'
                                    },
                                    data : dataTrade
                                };
                                let execProd = await axios(configUpdate);
                               
                                console.log(execProd.data);
                    /*

                    */
                    console.log(tradeUpdate);
                }else{
                  setMsgUnpageAlert("Unpacked image has been added but didn't sync. Please try again later.");
                }

              //LETSTRADE END
              setUpdateMe(2);
              setThumbsTwoU(true);
              setMsgUnpageAlert("");
            }
            setProcessing(false);
          }catch(err){
            console.log(err);
            setProcessing(false);
          }
      }else {
        console.log("Barcode is incorrect. It should match the current barcode.");
        setMsgUnpageAlert("Barcode is incorrect. It should match the current barcode.");
      }
   }else {
    console.log("Please use filename 'U_00000barcode.png'");
    setMsgUnpageAlert("Please use filename format as follows 'U_00000barcode.png'");
   }
   /*
    msMainAlert, setMsgMainAlert]         = useState("");
    const [msgLeftAlert, setMsgLeftAlert]        = useState("");
    const [msgRightAlert, setMsgRightAlert]      = useState("");
    */
  }

  ///////////////////////////////////////////////////
  const checkLeftImage  = () => {
    if(leftImage.current.files[0]){
        setLeftPreview(URL.createObjectURL(leftImage.current.files[0]));
    }
  }
  async function handleFormUploadLeftImage(e) {
   e.preventDefault(); 

    let fileNameText = leftImage.current.files[0].name.replace(/(.*)\.[^.]+$/, "$1");

    if(fileNameText.indexOf("L_") === 0){
        
        let leftFileName = fileNameText.substring(2);
      
        if(leftFileName === photo.barcode){
      
          setMsgUnpageAlert("");
          ///////////////////////////
          const formData = new FormData();
            formData.append("sideimageurl", leftImage.current.files[0]);
            formData.append("imgtype", "imgLeft");
            formData.append("picid", photo._id);
          
            setProcessing(true);
            try{
              const responsePicnic = await axios.post(CONSTANTS.API_URL+"pictures/sideimage" ,formData, {
                Headers: {
                  'Content-Type': 'multipart/form-data',
                  token: "Bearer " + user.accessToken
                }
              });

              //picnic list
              if(responsePicnic.data.status == "success"){
                //LETSTRADE START              
                    const resPicsTradeBarcode = await axios.get(CONSTANTS.LETSTRADE1+'product/products?page=1&barcode=' + photo.barcode, {
                              headers: {               
                                  'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN,
                              }
                          });
                          //UPDATE PRODUCT
                      if(resPicsTradeBarcode.data.success === true){
                            var product_id = resPicsTradeBarcode.data.content.product_list[0]._id;  
                            let newLeftURL = responsePicnic.data.updatedImage.imgLeft;
                            
                            let imgeLeft = [];
                            if(resPicsTradeBarcode.data.content.product_list[0].image_list.length === 0){
                                 imgeLeft = [
                                  {
                                      "url": newLeftURL,
                                      "order": 2
                                  }];
                            }else {
                              /////-->
                                  
                                  //Add the unpacked image if it exists
                                  if(responsePicnic.data.updatedImage.imgUnpack !==""){
                                        let item = {
                                          "url": responsePicnic.data.updatedImage.imgUnpack,
                                          "order": 1
                                        }
                                        imgeLeft.push(item);
                                    }

                                    //The left image has to be added to the list
                                    let item = {
                                      "url": newLeftURL,
                                      "order": 2
                                    }
                                    imgeLeft.push(item);

                                      if(responsePicnic.data.updatedImage.imgRight !==""){
                                          let item = {
                                            "url": responsePicnic.data.updatedImage.imgRight,
                                            "order": 3
                                          }
                                          imgeLeft.push(item);
                                      }
                                      //console.log(imgeLeft)
                              /////-->
                            }
                            
                            var tradeUpdateLeft = {
                                  "id": product_id,
                                  "image_list": imgeLeft
                              }
                         
                              var dataTrade = JSON.stringify(tradeUpdateLeft);
                                      /////

                                          var configUpdate = {
                                              method: 'put',
                                              url: CONSTANTS.LETSTRADE1 + 'product/update_product',
                                              headers: { 
                                                  'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN, 
                                                  'Content-Type': 'application/json'
                                              },
                                              data : dataTrade
                                          };
                                          let execProd = await axios(configUpdate);
                                      
                                          //console.log(execProd.data);
                              /*

                              */
                             
                          }else{
                            setMsgUnpageAlert("Unpacked image has been added but didn't sync. Please try again later.");
                          }
                      
                        //LETSTRADE END
                setUpdateMe(3);
                setThumbsThrL(true);
                setMsgLeftAlert("")
              }
              setProcessing(false);
            }catch(err){
              //console.log("The ancient of Days");
              //console.log(err);
              setProcessing(false);
            }
          ///////////////////////////
        }else {
          //console.log("Barcode is incorrect. It should match the current barcode.");
          setMsgLeftAlert("Barcode is incorrect. It should match the current barcode.");
        }
    }else {
      //console.log("Please use filename 'L_00000barcode.png'");
      setMsgLeftAlert("Please use filename format as follows 'L_00000barcode.png'");
    }

  }

  ///////////////////////////////////////////////////////
  const checkRightImage  = () => {
    if(rightImage.current.files[0]){
        setRightPreview(URL.createObjectURL(rightImage.current.files[0]));
    }
  }
  async function handleFormUploadRightImage(e) {
   e.preventDefault(); 
   

    let fileNameText = rightImage.current.files[0].name.replace(/(.*)\.[^.]+$/, "$1");
   

    if(fileNameText.indexOf("R_") === 0){
        
        let rightFileName = fileNameText.substring(2);
       
        if(rightFileName === photo.barcode){
     
          setMsgUnpageAlert("");
          ///////////////////////////
          
            const formData = new FormData();
            formData.append("sideimageurl", rightImage.current.files[0]);
            formData.append("imgtype", "imgRight");
            formData.append("picid", photo._id);
          
            setProcessing(true);
            try{
              const responsePicnic = await axios.post(CONSTANTS.API_URL+"pictures/sideimage" ,formData, {
                Headers: {
                  'Content-Type': 'multipart/form-data',
                    token: "Bearer " + user.accessToken
                }
              });

               //picnic list
              if(responsePicnic.data.status == "success"){
                //LETSTRADE START              
                const resPicsTradeBarcode = await axios.get(CONSTANTS.LETSTRADE1+'product/products?page=1&barcode=' + photo.barcode, {
                            headers: {               
                                'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN,
                            }
                        });
                          //UPDATE PRODUCT
                      if(resPicsTradeBarcode.data.success === true){
                            var product_id = resPicsTradeBarcode.data.content.product_list[0]._id;  
                            let newRightURL = responsePicnic.data.updatedImage.imgRight;
                            
                            //console.log(resPicsTradeBarcode.data);
                           
                            let imgeRight = [];
                            if(resPicsTradeBarcode.data.content.product_list[0].image_list.length === 0){
                                 imgeRight = [
                                  {
                                      "url": newRightURL,
                                      "order": 3
                                  }];
                            }else {
                              /////-->
                                  //console.log("Empire Right");
                                  //console.log(resPicsTradeBarcode.data.content.product_list[0].image_list[0]);

                                     /////////////////////////////////////////////
                                     //Add the unpacked image if it exists
                                     if(responsePicnic.data.updatedImage.imgUnpack !==""){
                                          let item = {
                                            "url": responsePicnic.data.updatedImage.imgUnpack,
                                            "order": 1
                                          }
                                          imgeRight.push(item);
                                      }
                                  
                                     //Add the left image if it exists
                                      if(responsePicnic.data.updatedImage.imgLeft !==""){
                                          let item = {
                                            "url": responsePicnic.data.updatedImage.imgLeft,
                                            "order": 2
                                          }
                                          imgeRight.push(item);
                                      }
                                      //The right image has to be added to the list
                                      let item = {
                                        "url": newRightURL,
                                        "order": 3
                                      }
                                      imgeRight.push(item);

                                      /////////////////////////////////////////////
                              /////-->
                            }
                            
                            var tradeUpdateRight = {
                                  "id": product_id,
                                  "image_list": imgeRight
                              }

                              //console.log("Right thing");
                              //console.log(tradeUpdateRight);
                              var dataTrade = JSON.stringify(tradeUpdateRight);
                                    /////
                                          var configUpdate = {
                                              method: 'put',
                                              url: CONSTANTS.LETSTRADE1 + 'product/update_product',
                                              headers: { 
                                                  'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN, 
                                                  'Content-Type': 'application/json'
                                              },
                                              data : dataTrade
                                          };
                                          let execProd = await axios(configUpdate);
                                          //console.log(execProd);
                                          //console.log(execProd.data);
                              /*
                              */
                              console.log("()()()()++++++++++++++++++()()()()");
                             
                          }else{
                            setMsgUnpageAlert("Unpacked image has been added but didn't sync. Please try again later.");
                          }
                      
                        //LETSTRADE END
                setUpdateMe(4);
                setThumbsFouR(true);
                setMsgRightAlert("")
              }
              setProcessing(false);
            }catch(err){
              
              console.log(err);
              setProcessing(false);
            }
          ///////////////////////////
        }else {
          console.log("Barcode is incorrect. It should match the current barcode.");
          setMsgRightAlert("Barcode is incorrect. It should match the current barcode.");
        }
    }else {
      console.log("Please use filename 'R_00000barcode.png'");
      setMsgRightAlert("Please use filename format as follows 'R_00000barcode.png'");
    }



  }
  
  const deleteSideImage = async (varFound) => {
    console.log(varFound);
    let obImage = {}
    let keyURL = photo.key;
    let deleteURL = "";
    var re = /(?:\.([^.]+))?$/;

    let udpatedImageListTrade = [];

    switch(varFound) {
      case 2:
        // upacked
        obImage = {
          "imgUnpack": ""         
        }
        deleteURL = "U_" +photo.barcode + "."+ re.exec(photo.imgUnpack)[1];
        //letstrade
                let itemLeft ={
                  "url": photo.imgLeft,
                  "order" : 2
                }
                if(photo.imgLeft !== ""){
                  udpatedImageListTrade.push(itemLeft);
                }
                let itemRight ={
                  "url": photo.imgRight,
                  "order" : 3
                }
                if(photo.imgLeft !== ""){
                  udpatedImageListTrade.push(itemRight);
                }
        break;
      case 3:
        // left
        obImage = {
          "imgLeft": ""
        }
        deleteURL = "L_" +photo.barcode + "."+ re.exec(photo.imgLeft)[1];
                //letstrade
                let itemUnpack ={
                  "url": photo.imgUnpack,
                  "order" : 1
                }
                if(photo.imgUnpack !== ""){
                  udpatedImageListTrade.push(itemUnpack);
                }
                let itemRight3 ={
                  "url": photo.imgRight,
                  "order" : 3
                }
                if(photo.imgRight !== ""){
                  udpatedImageListTrade.push(itemRight3);
                }
        break;
      default:
        // right
        obImage = {
          "imgRight": ""
        }
        deleteURL = "R_" +photo.barcode + "."+ re.exec(photo.imgRight)[1];
            //letstrade
            let itemUnpack2 ={
              "url": photo.imgUnpack,
              "order" : 1
            }
            if(photo.imgUnpack !== ""){
              udpatedImageListTrade.push(itemUnpack2);
            }
            let itemLeft2 ={
              "url": photo.imgLeft,
              "order" : 2
            }
            if(photo.imgLeft !== ""){
              udpatedImageListTrade.push(itemLeft2);
            }
    }
    
    let dataItem = JSON.stringify({
            "objecturl": {
              obImage
            },
            "photoid": photo._id,
            "key": keyURL, 
            "deletfile": deleteURL
          });
    
        try{
    
          var config = {
            method: 'post',
            url: CONSTANTS.API_URL+"pictures/removeside",
            headers: { 
              'Content-Type': 'application/json',
              'token': "Bearer " + user.accessToken
            },
            data : dataItem
          };
          //removepicture
          const responseRemove = await axios(config);

          //console.log(responseRemove.data);
          //letstrade delete start 
          const resPicsTradeBarcode = await axios.get(CONSTANTS.LETSTRADE1+'product/products?page=1&barcode=' + photo.barcode, {
                  headers: {               
                      'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN,
                  }
              });
              if(resPicsTradeBarcode.data.success === true){
                var product_id = resPicsTradeBarcode.data.content.product_list[0]._id;  

                var tradeUpdateDelete = {
                  "id": product_id,
                  "image_list": udpatedImageListTrade
                  }
              }
        
          var dataTrade = JSON.stringify(tradeUpdateDelete);
          /////
                var configUpdate = {
                    method: 'put',
                    url: CONSTANTS.LETSTRADE1 + 'product/update_product',
                    headers: { 
                        'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN, 
                        'Content-Type': 'application/json'
                    },
                    data : dataTrade
                };
                let execProd = await axios(configUpdate);
                //console.log(execProd);
                //console.log(execProd.data);
                

                setTimeout(function () {window.location.reload()}, 1500);
          //letstrade delete end
        }catch(er){
          console.log(er);
          console.log("Updating side image error")
        }
    }
  return (
    <div className="main-standard">
        <h2 className="lbl-title-page">Photo</h2>
        <div className="card">
            {
              photo && (
                <div className="photo-block">
                    <div className="photo-table">
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
               
                        <div className="but-small pd10 mt-3 lg:mt-0">
                          <Button className="btn btn-picky btsm1 mr-2" onClick={goBack} >
                            Back
                          </Button>
                          <Button
                              label="Edit"
                              className="btn btn-picky btsm1 mr-2"
                              onClick={() => setShowDialPhoto(true)}
                            />
                           {
                            user.isAdmin && (
                              <Button
                                label="Edit Supplier"
                                className="btn btn-picky btsm1 mr-2"
                                onClick={() => setShowDialPhotoSup(true)}
                              />
                            )
                           }
                         
                         <Button
                              label="Edit Category"
                              className="btn btn-picky btsm1 mr-2"
                              onClick={() => setShowDialPhotoCategroy(true)}
                            />
                        </div> 
                    </div>
                   
                    <DlgEditPhoto  
                        setShowDialPhoto={setShowDialPhoto}
                        showDialPhoto={showDialPhoto}
                        setSelectedPhotoInfo={setPhoto} 
                        selectedPhotoInfo={photo} 
                        presentList={presentList}
                        supplierName={suppli.title || ""} 
                        personAdmin={user}
                        />
                     
                    <DlgEditPhotoSupplier
                        setShowDialPhotoSup={setShowDialPhotoSup}
                        showDialPhotoSup={showDialPhotoSup}
                        setPhotoSupplier={setSupplier} 
                        photoSupplier={suppli} 
                        photoid={photo._id}
                        personAdmin={user}
                        />

                    <DlgEditPhotoCategory 
                        showDialPhotoCategory={showDialPhotoCategory} 
                        setShowDialPhotoCategroy={setShowDialPhotoCategroy}
                        photoid={photo._id}
                        pbarcode={photo.barcode}
                        personAdmin={user}
                        setUpdateMe={setUpdateMe}
                        updateMe={updateMe}
                        />
            
                    <table className="table table-striped table-border">
                        <tbody>
                          <tr>
                            <td className="left-pho-item">
                              <strong>Barcode</strong>
                            </td>
                            <td className="width30">:</td>
                            <td>{photo.barcode}</td>
                          </tr>
                          <tr>
                            <td className="left-pho-item">
                              <strong>Title</strong>
                            </td>
                            <td className="width30">:</td>
                            <td>{photo.title}</td>                          
                          </tr>
                          <tr>
                            <td>
                              <strong>Online Description</strong>
                            </td>
                            <td>:</td>
                            <td>{photo.onlineDesc}</td>
                            
                          </tr>
                          <tr>
                            <td>
                              <strong>Description</strong>
                            </td>
                            <td>:</td>
                            <td>{photo.description}</td>                        
                          </tr>
                          <tr>
                            <td>
                              <strong>Brand</strong>
                            </td>
                            <td>:</td>
                            <td>{photo.brand}</td>                        
                          </tr>
                          <tr>
                            <td>
                              <strong>Size</strong>
                            </td>
                            <td>:</td>
                            <td>{photo.size} {photo.unit}</td>
                        
                          </tr>
                          <tr>
                              <td>
                                <strong>Presentation</strong>
                              </td>
                              <td>:</td>
                              <td>{photo.presentation}</td>
                          </tr>
                          <tr>
                              <td>
                                <strong>Cost Price</strong>
                              </td>
                              <td>:</td>
                              <td>{photo.cost_price}</td>
                          </tr>
                          <tr>
                              <td>
                                <strong>Price</strong>
                              </td>
                              <td>:</td>
                              <td>{photo.price}</td>
                          </tr>
                          <tr>
                              <td>
                                <strong>Category</strong>
                              </td>
                              <td>:</td>
                              <td>{(photo.category && photo.category.length) && photo.category[0].name}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Draft Status</strong>
                            </td>
                            <td>:</td>
                            <td>{
                                  photo.draftmode  ? 
                                  <div className="">Hidden</div>
                                  : 
                                  <div className="">Visible</div>
                                }
                            </td>
                          </tr>
                          {
                            suppli && (
                              <tr>
                                <td>Supplier</td>
                                <td>:</td>
                                <td>{suppli.title}</td>
                              </tr>
                            )
                          }
                      
                        
                      </tbody>
                    </table>
                    </div>
                    <div className="photo-images ">
                      {
                        processing && (
                            <div className="img-source">
                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                            </div>
                        )
                      }
                      <div className="flexme">
                          <div className="vari-flex">
                            <div className="image-block">
                              <h4>Main Image</h4>
                              <img src={photo.imgurl} className="img-calls" />
                              <img className="smallpreview" src={imageMainpreview} alt="PickNick Preview image" />
                            </div>
                            <div className="rep-image">
                                  <form onSubmit={handleFormUploadMainImage}>
                                  <div className="form-group">
                                      <input type="file" className="form-control w80" onChange={() => checkMainImage()} ref={mainImage}  accept="image/*" required/>
                                  </div>
                                  <div className="form-group">
                                        <button type="submit" className="btn btn-picky" disabled={processing}>UPLOAD</button>
                                        
                                        {
                                          thumbsOne && (
                                            <div className="out-sp-success"><div className="add-thumb"><FontAwesomeIcon icon={faThumbsUp} /></div></div>
                                          )
                                        }
                                        
                                  </div>
                                </form>
                            </div>
                          </div>

                          <div className="vari-flex">
                            <div className="image-block">
                               {
                                  delTwoU && (
                                    <button className="btn-nostyle special-btn fl-left" onClick={() => deleteSideImage(2)}><FontAwesomeIcon icon={faTrash} /></button>
                                  )
                                }
                              <h4>Unpacked Image</h4>
                              {
                                (photo.imgUnpack !== "") && (
                                  <img src={photo.imgUnpack} className="img-calls" />
                                )
                              }
                              <img className="smallpreview" src={unpackpreview} alt="PickNick Preview image" />
                            </div>
                            <div className="rep-image">
                                
                                  <form onSubmit={handleFormUploadUnpackedImage}>
                                    <div className="form-group">
                                        <input type="file" className="form-control w80" onChange={() => checkUnpackedImage()} ref={unpackedImage}  accept="image/*" required/>
                                    </div>
                                    <div className="form-group">
                                          <button type="submit" className="btn btn-picky" disabled={processing}>UPLOAD</button>
                                        
                                          {
                                            thumbsTwoU && (
                                              <div className="out-sp-success"><div className="add-thumb"><FontAwesomeIcon icon={faThumbsUp} /></div></div>
                                            )
                                          }
                                    </div>
                                </form>
                                

                                {
                                  msgUnpackAlert && (
                                    <div className="mgtop20 alert alert-danger">
                                         {msgUnpackAlert}
                                    </div>
                                  )
                                }

                                {
                                        msUnpackAleDel && (
                                          <div className="mgtop20 alert alert-warning">
                                            {msUnpackAleDel}
                                          </div>
                                        )
                                      }
                            </div>
                          </div>
                      </div>
                      <div className="flexme">
                          <div className="vari-flex">
                                <div className="image-block">
                                {
                                      delThrL && (
                                        <button className="btn-nostyle special-btn fl-left" onClick={() => deleteSideImage(3)}><FontAwesomeIcon icon={faTrash} /></button>
                                      )
                                    }
                                  <h4>Left Image</h4>
                                  {
                                    (photo.imgLeft !== "") && (
                                      <img src={photo.imgLeft} className="img-calls" />
                                    )
                                  }
                                  <img className="smallpreview" src={leftpreview} alt="PickNick Preview image" />
                                </div>
                                <div className="rep-image">
                                   
                                      <form onSubmit={handleFormUploadLeftImage}>
                                      <div className="form-group">
                                          <input type="file" className="form-control w80" onChange={() => checkLeftImage()} ref={leftImage}  accept="image/*" required/>
                                      </div>
                                      <div className="form-group">
                                            <button type="submit" className="btn btn-picky" disabled={processing}>UPLOAD</button>
                                           
                                            {
                                                thumbsThrL && (
                                                  <div className="out-sp-success"><div className="add-thumb"><FontAwesomeIcon icon={faThumbsUp} /></div></div>
                                                )
                                              }
                                      </div>
                                    </form>
                                    
                                    {
                                      msgLeftAlert && (
                                        <div className="mgtop20 alert alert-danger">
                                             {msgLeftAlert}
                                        </div>
                                      )
                                    }

                                    {
                                        msgLeftAleDel && (
                                          <div className="mgtop20 alert alert-warning">
                                            {msgLeftAleDel}
                                          </div>
                                        )
                                      }
                                </div>
                              </div>
                              <div className="vari-flex">
                                  <div className="image-block">
                                      {
                                          delFouR && (
                                            <button className="btn-nostyle special-btn fl-left" onClick={() => deleteSideImage(4)}><FontAwesomeIcon icon={faTrash} /></button>
                                          )
                                        }
                                    <h4>Right Image</h4>
                                    
                                    {
                                      (photo.imgRight !== "") && (
                                        <img src={photo.imgRight} className="img-calls" />
                                      )
                                    }
                                    <img className="smallpreview" src={rightpreview} alt="PickNick Preview image" />
                                  </div>
                                  <div className="rep-image">
                                        
                                        <form onSubmit={handleFormUploadRightImage}>
                                        <div className="form-group">
                                            <input type="file" className="form-control w80" onChange={() => checkRightImage()} ref={rightImage}  accept="image/*" required/>
                                        </div>
                                        <div className="form-group">
                                              <button type="submit" className="btn btn-picky" disabled={processing}>UPLOAD</button>
                                              
                                              {
                                                thumbsFouR && (
                                                  <div className="out-sp-success"><div className="add-thumb"><FontAwesomeIcon icon={faThumbsUp} /></div></div>
                                                )
                                              }
                                              
                                        </div>
                                      </form>
                                      
                                      {
                                        msgRightAlert && (
                                          <div className="mgtop20 alert alert-danger">
                                              {msgRightAlert}
                                          </div>
                                        )
                                      }

                                      {
                                        msRightAleDel && (
                                          <div className="mgtop20 alert alert-warning">
                                            {msRightAleDel}
                                          </div>
                                        )
                                      }
                                  </div>
                                </div>

                          </div>
                    </div>
                </div>
              )
            }
        </div>
    </div>
  )
}

export default Photo