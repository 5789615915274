import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import DlgUser from '../components/DlgUser';
import DlgUserAdd from '../components/DlgUserAdd';
import { InputText } from 'primereact/inputtext';


function UserList() {

  const [userInfo, setUserInfo]                                = useState([]);
  const [selectedUserInfo, setSelectedUserInfo]                = useState([]);

  const { user }                                               = useContext(AuthContext);

  const [processing, setProcessing]                            = useState(false);
  const [globalFilter, setGlobalFilter]                        = useState('');
  
  const [msgFeedback, setMsgFeedback]                          = useState("");
  const [msgClass, setMsgClass]                                = useState("");
  const [newUser, setNewUser]                                  =  useState(0);
  const [supplierItems, setSupplierItems]                      = useState([]);
  const [userCount, setUserCount]                              =  useState(0);

  const [showDialogueSingleUser, setShowDialogueSingleUser]    = useState(false);
  const [showhowDialAddUser, setShowDialAddUser]               = useState(false);


  useEffect(() => {
    //console.log("User list");
    fetchAlltheUsers();
    getAllSuppliers();
  },[selectedUserInfo, newUser])

  const fetchAlltheUsers = async () => {
   // console.log(user);
   setProcessing(true);
    try{
        const res = await axios.get(CONSTANTS.API_URL +"users/", {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });
          //console.log(res);
          //console.log(res.data);
          setUserInfo(res.data);
          setUserCount(res.data.length);
          setProcessing(false);
    }catch(erros){
      console.log("USERSSS ERROR");
      console.log(erros);
      setProcessing(false);
    }
  }

  const checkGlobal = () => {
    console.log("Global variable is");
    console.log(globalFilter);
    setGlobalFilter('');
  }

  /////////////////////////////////////////////////////////////////////////////////////
  const headerTableUsers = (
    <div className="table-header flex justify-content-between">
      <h4 className="mx-0 my-1">Users  ({userCount})</h4>      
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search Users..." />
      </span>
    </div>
  );
  /////////////////////////////////////////////////////////////////////////////////////
  const imageUserTemplate = (rowData) => {
    //console.log(rowData);
    let profileURL = CONSTANTS.IMAGE_PROFILE;
    if(rowData.profilePic !== "-"){
      profileURL = rowData.profilePic;
    }
    return <div className="img-profile-container"><img src={profileURL} alt="image" className="profile-image"  /></div>;
  }
  const rolesUserTemplate = (rowData) => {
    //console.log("roles");
    //console.log(rowData);
    return <div className="capital rl">{rowData.roles}</div>
  }
  const actionUserTemplate = (rowData) => {

    return <div className="button-box"><button className="btn btn-picky btsm1"  onClick={() => {selectUserToView(rowData)}}>View</button></div>;
  }
  const  selectUserToView = (currentUser) => {
    //console.log("THIS WAS CLICKED");
    setSelectedUserInfo(currentUser);
    setShowDialogueSingleUser(true);
  }


  /////////////////////////////////////////////////////////////////////////////////////
  const getAllSuppliers = async () => {

      try {

        const res = await axios.get(CONSTANTS.API_URL +"suppliers/list", {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

        setSupplierItems(res.data);
        //setSupCount(res.data.length)     
        //console.log("+++++++++++++++++++++++++++");
      } catch (err) {       
          console.log(err);
          console.log(err.message);
        if(err.message === "Request failed with status code 403"){
          localStorage.removeItem("user_picknic2");
          document.location.href="/login";
        }
        console.log("ERRRRRRR")
      }
  }
  return (
    <div className="main-standard">
           <h2 className="lbl-title-page">Users</h2>
            <div className="page-items">
            <DlgUser showDialogueSingleUser={showDialogueSingleUser} setShowDialogueSingleUser={setShowDialogueSingleUser} setSelectedUserInfo={setSelectedUserInfo} selectedUserInfo={selectedUserInfo} personAdmin={user} />
            <DlgUserAdd showhowDialAddUser={showhowDialAddUser} setShowDialAddUser={setShowDialAddUser} newUser={newUser} setNewUser={setNewUser} supplierItems={supplierItems} personAdmin={user} />
                <div className="flexme">
                <div className="card flex-mg-space ">
                      <div className="card-body">  
                        <div className="but-small pd10 mt-3 lg:mt-0">
                          <Button
                                label="Add User +"
                                className="btn btn-picky btsm1 mr-2"
                                onClick={() => setShowDialAddUser(true)}
                              />
                        </div>                   
                    
                        <div className="tbl-info">
                          <DataTable 
                              value={userInfo} 
                              header={headerTableUsers}
                              paginator rows={20} rowsPerPageOptions={[20, 30, 40]}
                              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                              globalFilter={(globalFilter) ? globalFilter : ''}
                              responsiveLayout="scroll">
                              <Column header="Image" body={imageUserTemplate}></Column>
                              <Column field="name" header="Name"></Column>
                              <Column field="surname" header="Surname"></Column>
                              <Column field="email" header="Email"></Column>
                              <Column header="Role" body={rolesUserTemplate}></Column>
                              <Column header="Action" body={actionUserTemplate}></Column>
                          </DataTable>
                        </div>
                      </div>
                  </div>
                  
                  {
                        processing && (
                            <div className="img-source">
                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc profixed" />
                            </div>
                        )
                     }
                </div>
            </div>
    </div>
  )
}

export default UserList