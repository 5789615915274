import React from 'react'

function TBVCatalogue() {
  return (
    <div className="tv-box">
      <h3>Catalogue API</h3>
      <div className="base copy">
          <div className="copy-box">
              This is the base url structure:
          </div>
          <code>
          /api/catalogue
          </code>
          <div className="copy-box">
              <h4>Get Your Catalogues</h4>
              In order to make a request that will pull all the catalogues that belong to you. You will need to ensure that you extract your catalogue Token.
          </div>
      </div>
</div>
  )
}

export default TBVCatalogue