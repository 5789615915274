import React, { useRef, useState  } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

function DlgEditPhotoSupplier({setShowDialPhotoSup, showDialPhotoSup, setPhotoSupplier, photoSupplier, photoid, personAdmin}){

    const searchRef                                             = useRef();

    const [processing, setProcessing]                            = useState(false);

    const [msgFeedback, setMsgFeedback]                          = useState("");
    const [msgClass, setMsgClass]                                = useState("");
     
    const [record, setRecords]                                   = useState([]);

    const switchPictureSupplier = async (secSup) => {
       console.log(secSup);
       const userOb = {
            "supplierid": secSup._id,
            "photoid" : photoid
        }
        console.log(userOb);
        console.log("RRRRRR SSSSSSSS");
        console.log(photoSupplier);
       try{

            const response = await axios.put(CONSTANTS.API_URL+"pictures/updatepicturesup", userOb, {
                headers: {
                    token: "Bearer "+ personAdmin.accessToken
                }
            });
            console.log("2 Corrinthians")
            console.log(response.data);
            //add refresh button
            //setPhotoSupplier()
        
            if(response.data.status === "Success"){
                window.location.reload();
            }else {
                setMsgFeedback("Something went wrong, please try again later.")
                setMsgClass("alert-danger");
            }
       }catch(err){
        console.log(err);
        setMsgFeedback("Something went wrong, please try again later.")
        setMsgClass("alert-danger");
       }
    }
    async function handleFormSearchSupplier(e) {
        e.preventDefault();  
        setProcessing(true);   
        

        try{
            if(searchRef.current.value){
         
                   const suprep = await axios.get(CONSTANTS.API_URL+"suppliers/supfilter?searchfield="+searchRef.current.value, {
                    headers: {
                        token: "Bearer "+ personAdmin.accessToken
                    }
                });
                console.log(suprep.data);
                console.log("*************");
                setRecords(suprep.data.supData)

            }else {
                console.log("Text is empty");
                setMsgFeedback("Search field is empty");
                setMsgClass("alert-warning")
            }
            setProcessing(false);   
        }catch(err){
            console.log(err);
            setMsgClass("alert-danger")
            setMsgFeedback("Something went wrong, please try again later");
            setProcessing(false);   
        }   

    }

    return (
        <div className="block-comp">
            <Dialog header="Photo Edit"  visible={showDialPhotoSup} onHide={() => setShowDialPhotoSup(false)} style={{ width: '50vw' }}
                footer={
                <div>
                    {/* link button */}
                  
                    <Button className="btn btn-picky" label="Close" onClick={() => setShowDialPhotoSup(false)} />
                </div>
                }>
                      <div className="modal-body-content">
                         <div className="flexme">
                        
                            <div className="wide50">
                                <div className="card-box-block">
                                    <table className="table table-striped">
                                       <tbody>
                                            <tr>
                                                    <td>
                                                    Title 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {photoSupplier.title}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    Information 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {photoSupplier.information}
                                                    </td>
                                                </tr>
                                       </tbody>
                                    </table>
                                  </div>
                            </div>
                            <div className="wide50">
                                <div className="picture-quick-edit ">
                                       <div className="search-sup">
                                       <form onSubmit={handleFormSearchSupplier}>
                                                <div className="form-group">
                                                    <div className="lbldesc">Search Supplier:</div>
                                                    <input type="text" className="form-control wide100" ref={searchRef}  required/>
                                                </div>
                                                <div className="button-execs">
                                                    <button type="submit" className="btn btn-picky btn-full" disabled={processing}>SEARCH</button>         
                                                </div>
                                        </form>
                                            {
                                                msgFeedback && (
                                                <div className={"alert-area " + msgClass}>
                                                    {msgFeedback}
                                                </div>  
                                                )
                                            }
                                        </div>

                                        <div className="pd10">
                                             <div className="scroll-short">
                                             <ul className="no-list-style">
                                                {record && (
                                                        record.map((rec) => {
                                                            return (<li className="list-item-block srch-list-unfound" key={rec._id}>
                                                                        <div className="titling">
                                                                            <span>{rec.title}</span>
                                                                        </div>
                                                                        <div className=""><button className="btn btn-picky btsm1" onClick={() => switchPictureSupplier(rec)}>Select</button></div>
                                                                    </li>)
                                                        })
                                                    
                                                    )}
                                                  </ul>
                                             </div>
                                        </div>
                                    </div>
                            </div>
                            
                         </div>
                         {
                            processing && (
                                <div className="img-source">
                                <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                                </div>
                            )
                        }
                      </div>
                </Dialog>
        </div>
      )
}
export default DlgEditPhotoSupplier