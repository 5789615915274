import React, { useContext, useState } from 'react'
import TBVSalesCategories from '../components/TBVSalesCategories';
import TBVSalesPictures from '../components/TBVSalesPictures';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { AuthContext } from '../context/AuthContext';
import TBVSalesSuppliers from '../components/TBVSalesSuppliers';
import TBVSalesCatLayer from '../components/TBVSalesCatLayer';

function SalesLayer() {

  const { user } = useContext(AuthContext);
  
  const [activeIndex, setActiveIndex]               = useState(0);


  //functions
  const toggleSelectedTab = (index) => {
      setActiveIndex(index);
  } 

  return (
    <div className="main-standard">
        <h2 className="lbl-title-page">Sales Layer</h2>
        <div className="card tbl-sm">
          
            <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                  {/*
                <AccordionTab header="Sale Layer Pictures">                    
                    <TBVSalesPictures person={user}/>
                </AccordionTab> 
                */}
          
                     {/**The categories that retain the sales layer infrastructure so that they can be used to find the 
                      * newly structured categories from lets trade
                      * <AccordionTab header="Sales Layer Structured Categories "> 
                      *     <TBVSalesCatLayer person={user}/>     
                      * </AccordionTab>
                      */}                  
                
                <AccordionTab header="Categories">    
                  {/**The categories to be formated for letstrade
                      */}                    
                    <TBVSalesCategories person={user}/>
                </AccordionTab>    
                
                <AccordionTab header="Suppliers">                    
                    <TBVSalesSuppliers person={user}/>
                </AccordionTab>              
            </Accordion>
        </div>
             
    </div>
  )
}

export default SalesLayer