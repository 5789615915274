import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import './home.css';

function CUSSupplierUsers({user}) {
    const [userNumber, setUserNumber]       = useState(0);
    const [supUsers, setSupUsers]           = useState([]);

    useEffect(()=> {
        geSupplierUsers();
    },[]);

    const geSupplierUsers = async () => {
     
      
        console.log(user.extras[0].supplierId);
        const supID = user.extras[0].supplierId;

        const res = await axios.get(CONSTANTS.API_URL +"users/supusers?supplierid=" + supID, {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });
        console.log(res.data);
        setSupUsers(res.data)
        setUserNumber(res.data.length)
    }
  return (
    <div className="col-md-6">
         <div className="card flex-mg-space ">
            <div className="card-body widget-body">
                <div className="home-dash-item">
                    
                   <div className="flexme">
                        <div className="ico-title ico-tt-blue">
                                <FontAwesomeIcon icon={faUsers} /> 
                        </div>
                         <div className="mglt20 gnum">{userNumber}</div>
                   </div>
                    <div className="scroll-users">
                            {
                                supUsers && (
                                    <div className="users-home">
                                    {
                                         supUsers.map((suppUser, i) => {
                                            return (
                                                <div className="cont-user">
                                                    <div className="flexme">
                                                        <div className="hc hc-profile-cont-outer">
                                                        {(suppUser.profilePic === '-' 
                                                            ? <div className="profile-cont-inn" style={{ 
                                                                backgroundImage: `url(${CONSTANTS.IMAGE_PROFILE})` 
                                                                }}></div> 
                                                            : 
                                                            <div className="profile-cont-inn" style={{
                                                                backgroundImage: `url(${suppUser.profilePic})` 
                                                                }}>

                                                                </div>)}
                                                           
                                                        </div>
                                                        <div className="hc hc-content-outer">
                                                            <div className="hc-content-inn">
                                                                <h4>{suppUser.name} {suppUser.surname}</h4>
                                                                <div className="ld-cont">
                                                                    <strong> {suppUser.email}</strong>
                                                                </div>
                                                                <div className="ld-cont">
                                                                     {suppUser.phonenumber}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                )
                            }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CUSSupplierUsers