import React, { useContext, useEffect, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';

function Catalogue() {
  const { user } = useContext(AuthContext);
  const [catalogueItems, setCalalogueItems]     = useState([]);
  const [processing, setProcessing]             = useState(false);
  
  useEffect(() => {
      getAllCatalogue();
  },[])

  const getAllCatalogue = async () => {
    setProcessing(true);
    try {
      
      const res = await axios.get(CONSTANTS.API_URL +"catalogues/list", {
          headers: {
              token: "Bearer "+ user.accessToken
          }
      });
      //console.log(res.data);
      setCalalogueItems(res.data);
      setProcessing(false);
    } catch (err) {
      setProcessing(false);
      console.log(err);
      if(err.message === "Request failed with status code 403"){
        localStorage.removeItem("user_picknic2");
        document.location.href="/login";
       }
      console.log("ERRRRRRR")
    }


  }

 
  return (
    <div className="main-standard">
       <h2 className="lbl-title-page">Catalogue</h2>
       <div className="list-catalogue">
           <div className="card">
              <div className="card-body">
                <div className="grid-catas">
                {
                    catalogueItems && (
                      catalogueItems.map((logs, i) => {
                        return (<div className="mp-block-item" key={i}>
                            
                              <Link to={"/catalogue-details/" + logs._id} className="cat-links">
                                  {logs.title}
                              </Link>
                          </div>
                          )
                      })
                    )
                }
                </div>
              </div>
              {
                        processing && (
                            <div className="img-source">
                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc profixed" />
                            </div>
                        )
                     }
           </div>

       </div> 
    </div>
  )
}

export default Catalogue