import React, { useContext, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faUpload, faFileExcel, faImagePortrait , faThumbsUp, faProjectDiagram} from '@fortawesome/free-solid-svg-icons';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import * as XLSX from 'xlsx';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from "react-router-dom";

function FileImports() {
  const navigate                               = useNavigate()
  const { user }                               = useContext(AuthContext);

  const [addImportMsg, setAddImportMsg]                  = useState("");
  const [addImportMsgNuetral, setAddImportMsgNuetral]    = useState([]);
  const [unAssigned, setUnAssigned]                      = useState([]);
  const [imageExcels, setImageExcels]                    = useState([]);
 
  const [processing, setProcessing]                      = useState(false);
  const [imageImports, setImageImports]                  = useState([]);
  const [totalImages, setTotalImages]                    = useState(0);
  
  const directoryBoxRef                                  = useRef();
  const cobolFileRef                                     = useRef();
  const mainCSVRef                                       = useRef("");
  
  useEffect(() => {
    if(!user.isAdmin){
      navigate("/access-denied");
    }
    getAccessMongoImages();
  },[addImportMsgNuetral]);
  
  const getAccessMongoImages = async () => {
      try{
  
        setProcessing(true);
        const response = await axios.get(CONSTANTS.API_URL + "pictures/pureimages"); 
        console.log(response.data);
        setUnAssigned(response.data);
        setProcessing(false);
      }catch(err){
        console.log("Fetch current unassigned error");
        console.log(err);
        setProcessing(false);
      }
  }

  async function handleFormUploadImages(e) {
    e.preventDefault();

    if(directoryBoxRef.current.files[0].length != 0){
      setProcessing(true);
      //////////////////1 Start
      let imgCount = 0;
      try{
        
        for (const file of directoryBoxRef.current.files) {  
          const formData = new FormData();
          ///////..............////// 
          let barcodeIs = file.name.replace(/\.[^/.]+$/, "");     
          formData.append("singlebulk", file);
          formData.append("barcode", barcodeIs);

          const response = await axios.post(CONSTANTS.API_URL+"pictures/pureupload" ,formData, 
              {
              headers: {
                        'Content-Type': 'multipart/form-data',
                        token: "Bearer " + user.accessToken
                  }
              });

           setImageImports(oldArr => [...oldArr , response.data])
          // console.log(response.data);
          ///////..............//////
          imgCount++;
        }
        setTotalImages(imgCount);
       
        setProcessing(false);
      }catch(err){
        console.log("Oteng");
        console.log(err);
        setProcessing(false);
      }
      //////////////////1 END
    }
  }

  async function handleFormUploadExcelSheet(e) {
    e.preventDefault();
    if(mainCSVRef.current.files[0].length != 0){
      setProcessing(true);
      /////////////////#1 start
      const f = mainCSVRef.current.files[0] ;
      const data = await f.arrayBuffer();
      var workbook = XLSX.read(data);  
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const dataJson  = XLSX.utils.sheet_to_json(worksheet);
      const finalBarcodes  =  [];
      let stringBarcodes = "";
      /////////////////#2 start
      try{
        //console.log(dataJson);
        dataJson.forEach((el)=>{
              console.log(el);
             // console.log("Yeaaap ////////////////////////////////////");
              ////////////////#3 Start
              const filterArrBarcode = unAssigned.filter(function(valImage) {
                   
                     if(valImage.barcode === el.barcode){
                        
                        stringBarcodes = stringBarcodes + ", " + valImage.barcode;
                        finalBarcodes.push(el);
                        return el;
                    }
                });
              //console.log(filterArrBarcode);
             //console.log("////////////////#");
              ////////////////#3 End
          })
          //console.log("eyyooo");
          //console.log(stringBarcodes);

        //setImgExcelBarcodes
         // console.log("Un Assigned");
         // console.log(unAssigned);
         
        // console.log("After looop");
        // console.log(finalBarcodes);
         const obsSendProductImages = [];
         const obsSendDelImages     = [];

         //setImageExcels(finalBarcodes);     
       
          for (let i = 0; i < finalBarcodes.length; i++) {
            for(let imgS3 of unAssigned){
               if(imgS3.barcode == finalBarcodes[i].barcode){
                      /*
                      console.log(i + " #inside Image");
                      console.log("=======><=========");
                      console.log(imgS3);
                      console.log(finalBarcodes[i]);
                      */
                      const proImage = {
                        "barcode":finalBarcodes[i].barcode,
                        "title":finalBarcodes[i].title,
                        "imgurl": imgS3.Location,
                        "imgLeft":"",
                        "imgRight":"",
                        "imgUnpack":"",
                        "key": imgS3.key,
                        "onlineDesc": finalBarcodes[i].onlineDesc,
                        "description": finalBarcodes[i].description,
                      }

                      console.log("Hello World 0"); 
                      obsSendProductImages.push(proImage);
                         console.log(proImage);                  
                     //  console.log("Hello World 2");                     
                     //  console.log("DELETE " + imgS3._id);
                      obsSendDelImages.push(imgS3._id);
                  }
                }
            }

              //  console.log(imageExcels);
              //console.log(frmCodes);
              //setImgExcelBarcodes(finalBarcodes);
              //console.log(imgExcelBarcodes);
              ////////////////#4 Start
              console.log("PRODUCTS TO BE ASSIGNED")
              console.log(obsSendProductImages);

              console.log("IMAGES DELETED")
              console.log(obsSendDelImages);
        /* 
        ####*/
         const response = await axios.post(CONSTANTS.API_URL+"pictures/puretopictures" , obsSendProductImages);
         console.log("Ho lela hao thusi");
         console.log(response.data);
         const delrep = await axios.post(CONSTANTS.API_URL+"pictures/puretopicturesdelete" , obsSendDelImages);
         console.log("Deleta mo thusi");
         console.log(delrep.data);
         setImageExcels(obsSendProductImages);
         /*####
         */
         ////////////////#4 End
        setProcessing(false);
      }catch(error){
        console.log(error);
        console.log("Excell post");
        setProcessing(false);
      }
      /////////////////#2 end

      /////////////////#1 end
    }
  }
 


  const sendCobolFile = async (cobsFile) => {
    console.log("******************");
    //console.log(cobs);
    console.log("******************");
    
    try{

      const formData = new FormData();
         
      formData.append("cobolfile", cobsFile);
      //const response = await axios.post(CONSTANTS.API_URL+"pictures/nuetralfile" , JSON.stringify(cobs), {
      const response = await axios.post(CONSTANTS.API_URL+"pictures/nuetralfile" , formData, {
        Headers: {
          'Content-Type': 'multipart/form-data'
         },
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
      });
      //setAddImportMsgNuetral()
      console.log(response.data);
     
    }catch(err){
      console.log("Cap");
      console.log(err)
    }
  }
  return (
    <div className="main-standard">
      <h2 className="lbl-title-page">Imports</h2>
      <div className="card">
         <div className="card-body">

                  <div className="file-item-input">
                     <h3>Images Upload</h3>
                     
                        <div className="flexme">
                             <div className="spaceshare2">
                             <div className="text-500 mb-5">
                                This upload is for a bulk upload of the main images that will be on standby for an excell sheet that will add the missing data.
                                The images will not appear in the library until updated  by the excel sheet when it is important.  
                              </div>
                                <div className="ft1">
                                <form onSubmit={handleFormUploadImages} encType="multipart/form-data">
                                    <div className="form-group fileitems">                                       
                                          <div className="input-block-group">                                         
                                              <div className="ico-title ico-tt-blue mgbot20"><FontAwesomeIcon icon={faImagePortrait} /></div>
                                              <input
                                                      type="file"                                                        
                                                      className="form-control"
                                                      ref={directoryBoxRef}
                                                      multiple
                                                      required/>  
                                          </div>                                      

                                        <div className="button-row">
                                          <button className="btn btn-picky" >  <FontAwesomeIcon icon={faUpload} /> Import</button>  
                                        </div>                   
                                    </div>
                                  </form>

                                  <div className="fmg">
                                      {
                                          addImportMsg && (
                                          <div className="alert-area alert-success">
                                              {addImportMsg}
                                          </div>
                                          )
                                      }
                                  </div>
                                </div>
                             </div>
                             <div className="spaceshare2">
                                  <div className="box-conain-list">
                                      <ul>
                                        {
                                          imageImports && (
                                            imageImports.map((jalert, i) => {
                                              return <li className="li-item-sent" key={i}>
                                                <div className="bs-info-job success">
                                                    <div className="">
                                                        {jalert.resData.barcode}
                                                    </div>
                                                    <div className="sp-icon">
                                                        <FontAwesomeIcon icon={faThumbsUp} />
                                                    </div> 
                                                </div>
                                              </li>
                                            })
                                          )
                                        }
                                          </ul>
                                  </div>
                                  {
                                    (totalImages > 0 ) && (
                                      <div className="status-count">
                                        {totalImages}
                                      </div>
                                    )
                                  }
                             </div>
                        </div>
                  </div>
                
                  <div className="file-item-input">
                     <h3>Excel Upload</h3>
                     <div className="text-500 mb-5">
                        This import is for an excel sheet of that will check for all bulk added images and with its full data create the picture products in the library for consumption.
                      </div>
                     <div className="flexme">
                             <div className="spaceshare3">
                                    <div className="ft1">
                                        <form onSubmit={handleFormUploadExcelSheet} encType="multipart/form-data">
                                            <div className="form-group fileitems">
                                               <div className="input-block-group">
                                               <div className="ico-title ico-tt-green mgbot20"><FontAwesomeIcon icon={faFileExcel} /></div>
                                              <input type="file" ref={mainCSVRef} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" className="form-control" required/>   
                                              </div>
                                            </div>
                                            <div className="button-row">
                                              <button className="btn btn-picky" >  <FontAwesomeIcon icon={faUpload} /> Import</button>  
                                            </div>  
                                         </form>
                                    </div>
                                    <div className="pdtb10">
                                        <img src={CONSTANTS.EXCEL_IMPORT} className="img-rep wfull" />
                                    </div>
                              </div>
                              <div className="spaceshare3">
                                  <div className="box-conain-list">
                                  {
                                  unAssigned && (
                                     <h3>Total unassigned <span className="lgcicular">{unAssigned.length}</span></h3>
                                     )
                                  }
                                   
                                      {unAssigned && (
                                        unAssigned.map((mongoItems) => {
                                          return (
                                            <div className="mong-item item" key={mongoItems._id}>
                                                <div className="bar-items">
                                                  {mongoItems.barcode}
                                                </div>
                                            </div>
                                          )
                                        })
                                      )
                                      }
                                  </div>
                             </div>
                              <div className="spaceshare3">
                                  <div className="box-conain-list">
                                      <ul>
                                        {
                                          imageExcels && (
                                            imageExcels.map((excell, i) => {
                                              return <li className="li-item-sent" key={i}>
                                                <div className="bs-info-job success">
                                                    <div className="">
                                                      {excell.barcode}
                                                    </div>
                                                    <div className="sp-icon">
                                                        <FontAwesomeIcon icon={faThumbsUp} />
                                                    </div> 
                                                </div>
                                              </li>
                                            })
                                          )
                                        }
                                          </ul>
                                  </div>
                             </div>
                             
                      </div>
                  </div>
             
         </div>
         
         {
              processing && (
                  <div className="img-source">
                  <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc2" />
                  </div>
              )
          }
      </div>
    </div>
  )
}

export default FileImports