import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";

function TBVSalesSuppliers({person}) {
    const [supplier, setSupplierList]                            = useState([]);
    const [selectedSupplier, setSelectedSupplier]                = useState(null);
    const [globalFilter, setGlobalFilter]                        = useState('');
    const [msgErr, setMsgErr]                                    = useState('');
    const [msgSuc, setMsgSuc]                                    = useState('');
    const [supplySearched, setSupplySearched]                    = useState(false);
    const [supCount, setSupCount]                                = useState(false);
    const [processing, setProcessing]                            = useState(false);
    
    useEffect(() => {
        getSuppliers();
      },[]);

      const headerTable = (
        <div className="table-header flex justify-content-between">
          <h4 className="mx-0 my-1">Suppliers ({supCount})</h4>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search Categories..." />
          </span>
        </div>
      );
      const supplierTransferTemplate = (rowData) => {
        
        return  <Button
                    className="btn btn-circle btsm1 mr-2"
                    label="Transfer"
                    onClick={() => transferSupplier(rowData)}
                />
        ;
       }

      ////////////////////
      const transferSupplier = async (rData) => {
        /*
        console.log("Bucket LIST ");
        console.log(rData);
        */
        setProcessing(true);
        try{
            const suppItem = {
                "title":rData.title,
                "information": "",
                "slayer": rData.slayer,
            }

            const resSupp = await axios.post(CONSTANTS.API_URL + "suppliers/",suppItem,{
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            } );
            //console.log(resSupp.data);

            setMsgSuc("Successfully transferred the supplier " + rData.title)
            setProcessing(false);
        }catch(err){
            setProcessing(false);
            console.log(err);
        }
      }

      const getSuppliers = async () => {
        try{

           const resSupplier = await axios.get(CONSTANTS.SALES_LAYER_SUP_FTCH);
             setSupplySearched(true);           
             setSupplierList(resSupplier.data.data);
             setSupCount(resSupplier.data.data.length)
           

       }catch(ers){
           console.log(ers);
           console.log("Fetch Er");
       }
     }

     const addSalesLayerSuppliers = async ()=> {
        setProcessing(true);
        try{
           // console.log("Suppliii all");
           //console.log(supplier);

          const responseSupplier = await axios.post(CONSTANTS.API_URL+"suppliers/addbulk", supplier, {
              headers: {
                  token: "Bearer "+ person.accessToken
              }
          });
            //console.log(responseSupplier.data);
            setMsgErr("");
            setMsgSuc("Successfully transferred all suppliers")
            setProcessing(false);
        }catch(err){
            setMsgErr("Something went wrong");
            setMsgSuc("")
            setProcessing(false);
        }
     }
  return (
    <div className="tv-box">
          {
            msgSuc && (
                <div className="alert alert-success">
                    {msgSuc}
                </div>
            )
          }
          {
             (supplier.length > 0) ? (
                <>
                   <DataTable
                        value={supplier}
                        header={headerTable}
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        responsiveLayout="scroll"
                        globalFilter={(globalFilter) ? globalFilter : ''}
                        selection={selectedSupplier}
                        onSelectionChange={e => setSelectedSupplier(e.value)}
                        >

                            <Column field="title" header="Title"></Column>
                            <Column header="Transfer" body={supplierTransferTemplate}></Column>
                  </DataTable>
                  
                  <div className="pdtb10">
                          <Button
                                  className="btn btn-circle btsm1 mr-2"
                                  label="Bulk Transfer"
                                  onClick={() => addSalesLayerSuppliers()}
                                  />
                      </div>
                </>
              )
              :
              <div className="">
                 {
                 supplySearched ? 
                 <h2>Nothing found</h2>
                 :
                 <div className="">Loading...</div>
                }                
              </div>
          }

        {
            msgErr && (
                <div className="alert alert-danger">{msgErr}</div>
            )
          }
          {
                processing && (
                    <div className="img-source">
                    <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                    </div>
                )
           }
    </div>
  )
}

export default TBVSalesSuppliers