import React, { useContext, useRef, useState } from 'react'
import './login.css';
import axios from "axios";
import * as CONSTANTS from "../CONSTANTS";
import { Link } from 'react-router-dom';
import { login } from "../context/apiCalls";
import { AuthContext } from "../context/AuthContext"
import { useNavigate } from "react-router-dom";

function Login() {
  const { isFetching, error, dispatch } = useContext(AuthContext);

  const [erEmpty, setErEmpty]            = useState("");
  const emailRef     = useRef();
  const passwordRef  = useRef();

  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
  
        const email    = emailRef.current.value;
        const password = passwordRef.current.value;
      try{

          if((email !== "") && (password !== "")){

            // login({ email, password }, dispatch);
            let emailCheckTest = isValidEmail(email);
              console.log(emailCheckTest);

              if(emailCheckTest){
                  
                    const userPreson = {
                      "email" : email,
                      "password" : password
                    }
                    const res = await axios.post(CONSTANTS.API_URL + "auth/login", userPreson);
            
                    console.log(res.data);
                    localStorage.setItem(CONSTANTS.SESSION_COOKIE, JSON.stringify(res.data));
                  
                    navigate("/", { reload: true });
                   
                    setTimeout(function() {
                      window.location.reload();
                    }, 2000);
                    
               
              }else {
                setErEmpty("Email not in a valid format.")
              }
             
          }else {
            setErEmpty("Email or password cannot be empty")
          }
                 
                  
      }catch(errsLog){
          console.log(errsLog);
          if(errsLog.response.status === 401){
            console.log(errsLog.response.data);
            setErEmpty(errsLog.response.data)
          }
      }
  }

  function isValidEmail(email){
    return /\S+@\S+\.\S+/.test(email);
  }
  return (
    <div className="logo-base flexlog">
        <div className="log-start">
          <div className="lgin-spot">
            <h3>Login</h3>
            <form encType="multipart/form-data">
              <div className="form-group frg">
                <div className="text-form">Email: </div>
                <input type="email" className="form-control ct-content wide100" ref={emailRef}  placeholder="Enter Email Address" required/>
              </div>
              <div className="form-group frg">
                <div className="text-form">Password: </div>
                <input type="password" className="form-control ct-content wide100"  ref={passwordRef} placeholder="Enter Password" required/>
              </div>
              <div className="form-group frg">
                 <button className="btn btn-redish" onClick={handleLogin} disabled={isFetching}>Sign In</button>
              </div>
              <div className=" frg">
                <Link to="/sign-up">
                  Sign Up.
                </Link>
              </div>
            </form>
            <div className="frg gray-link mgtopMin35 flright">
                  <Link to="/forgot-password">
                  Forgot Password
                  </Link>
                </div>
            {
                error && (
                  <div className="alert-area alert-danger">
                    Incorrect email or password.
                  </div>
                )
            }
            {
              erEmpty && (
                <div className="alert-area alert-danger">
                    {erEmpty}
                </div>
              )
            }
          </div>

         {
          isFetching && (
            <div className="img-source">
              <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
            </div>
          )
         }
        </div>
        <div className="log-image-pot" style={{
             backgroundImage: `url(${CONSTANTS.LOGIN_BACKGROUND})` }}>      
        </div>
    </div>
  )
}

export default Login