import React, {useState , useRef, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";


function DlgAddPipelineItem(props) {

    const pipeTitleRef                            = useRef();
    const catalogueRef                            = useRef();
    
    const [msg , setMessage]                      = useState("");
    const [msgAlert , setMessageAlert]            = useState("");
    const [catalogueList, setCatelogueList]       = useState([]);
    const [foundPipeCat, setFoundPipeCat]         = useState(false);

    useEffect(() => {
      getCataloguesToChooseFrom();
    },[]);

    const getCataloguesToChooseFrom = async () => {
      try{
        //console.log("getCataloguesToChooseFrom");
            const catalogs = await axios.get(CONSTANTS.API_URL +"catalogues/list", {
                  headers: {
                      token: "Bearer "+ props.person.accessToken
                  }
              });
            //console.log(catalogs.data);
            setCatelogueList(catalogs.data);
            if(catalogs.data.length > 0){
              setFoundPipeCat(true)
            }
      }catch(err){
        console.log("Pipe Pop Up Catalogue Error");
        console.log(err);
      }
    }
    const handleSubmitPipeItem = async (e) => {
        e.preventDefault();
        try{
            console.log("");
           
              if(pipeTitleRef.current.value.length > 1){
                console.log(catalogueRef.current.value)
                
                let textSelectOption = catalogueRef.current.options[catalogueRef.current.selectedIndex].text;
                console.log(textSelectOption);
               
                const pipWork = {
                    "title": pipeTitleRef.current.value,
                    "catalogue":  textSelectOption,
                    "catalogueid" : catalogueRef.current.value
                  }

                  var config = {
                    method: 'post',
                    url: CONSTANTS.API_URL + "catalogues/pipes",
                    headers: { 
                        token: "Bearer "+ props.person.accessToken
                    },
                    data:pipWork
                  };
                  const res = await axios(config);
                  console.log('PIPE FEED');
                  console.log(res.data)
                setMessageAlert("alert-success");
                setMessage("Pipe Item created successfully");
              
              }else {
                setMessageAlert("alert-danger");
                setMessage("Title needs more characters");
              }
        }catch(ers){
            console.log(ers)
            setMessageAlert("alert-danger");
            setMessage("Something went wrong");
        }
    }
  return (
    <Dialog header="Create Pipe Item" visible={props.showPipelineForm} style={{ width: '50vw' }} modal={true} onHide={() => props.setShowPipelineForm(false)}
            footer={
                <div>                 
                    <Button className="p-button-danger" label="Close" onClick={() => props.setShowPipelineForm(false)} />
                </div>
            }>
           <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                  
                    <form  onSubmit={handleSubmitPipeItem}>
                        <div className="form-group">
                            <div className="lbldesc">Pipe Title*: </div>
                            <input type="text" ref={pipeTitleRef} className="form-control wfull" required/>
                        </div>
                        <div className="form-group">
                            <div className="lbldesc">Catalogue For Pipeline*: </div>
                            {
                                catalogueList && (
                                    <select className="form-control w80" ref={catalogueRef}>
                                          {catalogueList.map((catItem) => {
                                            return <option className="opt" value={catItem._id} key={catItem._id}>{catItem.title}</option>
                                          }) }
                                    </select>
                                )
                            }
                        </div>
                        {foundPipeCat && (
                          <div className="height-item">
                            <button className="btn btn-picky" >CREAT ITEM</button>
                        </div>
                        )}
                        
                    </form>

                    {
                        msg &&(
                            <div className={"alert " + msgAlert}>
                                 {msg}
                            </div>
                        )
                    }
                </div>
            </div>
    </Dialog>
  )
}

export default DlgAddPipelineItem