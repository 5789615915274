import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { Button } from 'primereact/button';

function TBVSetPictoTrade({person}) {

    const [msgFeed, setMsgFeedback]                      = useState("");
    const [msgClass, setMsgClass]                        = useState("");
    const [listPicPictures, setListPicPictures]          = useState([]);
    const [ltTradePictures, setLtsTradPictures]          = useState([]);
    const [transferRecord, setTransferRecord]            = useState([]);
    const [transferRecordV2, setTransferRecordV2]        = useState([]);
    const [transRecNum, setTransRecNum]                  = useState(false);
    const [tradeCount, setTradeCount]                    = useState(0);
    const [picCount, setPicCount]                        = useState(0);

    const [processing, setProcessing]                    = useState(false);
    const [procPicnic, setProcPicnic]                    = useState(false);
    const [procTradeGo, setProcTradeGo]                  = useState(false);

    useEffect(()=> {
        listAllPicNicPictures();
    },[]);

    useEffect(()=> {
        listAllLetsTrade();
    },[]);

    const listAllPicNicPictures = async () => {
        setProcPicnic(true);
        try{

            const resPics = await axios.get(CONSTANTS.API_URL +"pictures/assembly", {
                headers: {               
                    token: "Bearer "+ person.accessToken
                }
            });
            console.log("Picto trade")
            console.log(resPics.data);
            setListPicPictures(resPics.data);
            setPicCount(resPics.data.length);
            setProcPicnic(false);
        }catch(ers){
            console.log(ers);
            setProcPicnic(false);
        }
    }

    const listAllLetsTrade = async () => {
        try{
            setProcTradeGo(true);
            const resPicsTrade = await axios.get(CONSTANTS.LETSTRADE1+'product/products', {
                headers: {               
                    'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN,
                }
            });
            //console.log(resPicsTrade.data);
            setLtsTradPictures(resPicsTrade.data.content.product_list);
            setTradeCount(resPicsTrade.data.content.count);
            setProcTradeGo(false);
        }catch(ers){
            console.log(ers);
            setProcTradeGo(false);
        }
    }
    const executeTransferWork = async () => {

        try{
            const productList = [];
            setProcessing(true);
            ////////////////////
            //LOOP START
            var lengthPictures = listPicPictures.length;
            //var lengthPictures = 1000;
            for (let i = 0; i < lengthPictures; i++) {
                const theBarcode = listPicPictures[i].barcode;
                //CHECK IF IT EXISTS START
                var configBar = {
                    method: 'get',
                    url: CONSTANTS.LETSTRADE1 + 'product/products?page=1&barcode=' + theBarcode,
                    headers: { 
                        'Authorization': 'Bearer ' + CONSTANTS.LTTOKEN,
                    }
                  };

                const barcodeFound = await axios(configBar);
               // console.log("################################################");
                //CHECK IF IT EXISTS END
                var currItem = {};
                if(barcodeFound.data.content.count === 1){
                    const num = 1 + i;
                    currItem = {
                        "counter" : num,
                        "title" :  listPicPictures[i].title,
                        "Barcode" : theBarcode,
                        "Status"  :  "Present",
                        "classAct" : "present"
                    }

                   //setTransferRecord([...transferRecord, currItem]);
                   //setTransferRecordV2([...transferRecordV2, currItem]);
                   setTransferRecordV2(currItem);
                   // console.log("[][][][][][][][][][]");
                    setTransRecNum(true);
                    productList.push(currItem);
                }else {
                     //////////////////////////////////////////////////////////////////////////
                     var drafter = "AVAILABLE";
                     if(listPicPictures[i].draftmode === true){
                        drafter = "NOT AVAILABLE";
                     }

                     var catyGo = {
                        description: "",
                        id : "ABC123abcd1234",
                        image_url : "",
                        name : "N A"
                     }

                        if(listPicPictures[i].category[0] == ""){                           
                        }else {
                            catyGo = {
                                "id": listPicPictures[i].category[0].id,
                                "name": listPicPictures[i].category[0].name,
                                "description": listPicPictures[i].category[0].description,
                                "image_url": listPicPictures[i].category[0].image_url,
                            }
                        }
                       // console.log(listPicPictures[i].category);
                        let pid = Math.floor((Math.random() * 1000) + 1) + "";
                       //letstrade 
                       var tradeCons = {
                            "image_url" : listPicPictures[i].imgurl,                                                     
                            "title" : listPicPictures[i].title,
                            "sub_title" : listPicPictures[i].onlineDesc,
                            "description" : listPicPictures[i].description,
                            "brand" : listPicPictures[i].brand,
                            "manufacturer": "",
                            "sku": listPicPictures[i].barcode,
                            "pid": pid,
                            "quantity_type" : listPicPictures[i].presentation,
                            "quantity" : listPicPictures[i].size,
                            "cost_price" : "",
                            "price" : listPicPictures[i].price,
                            "on_promotion" : false,
                            "promotion_price" : "",
                            "variations" : null,
                            "category" : catyGo,
                            "barcode" : listPicPictures[i].barcode,   
                            "notes" : "",
                            "extra_fields" : [],                           
                            "status": drafter,
                            "add_ons": null
                        }

                        var dataTrade = JSON.stringify(tradeCons);
                        //console.log("___------___-----____");
                     
                            var config = {
                                method: 'post',
                                url: CONSTANTS.LETSTRADE1 + "product/add_product",
                                headers: { 
                                    'Authorization': 'Bearer ' + CONSTANTS.LTTOKEN,
                                    'Content-Type': 'application/json'
                                },
                                data : dataTrade
                              };

                         const letsTradeAdd = await axios(config);

                         /// Transfer Status 
                         const num = 1 + i;
                          currItem = {
                                "counter" : num,
                                "title" :  listPicPictures[i].title,
                                "Barcode" :  theBarcode,
                                "Status" :  "Success",
                                "classAct" : "success"
                            }

                        //setTransferRecord([...transferRecord, currItem]);
                        
                        setTransRecNum(true);
                        setTransferRecordV2(currItem);
                        productList.push(currItem);
                        /// Transfer Status  END
                     //////////////////////////////////////////////////////////////////////////
                }
                //console.log("+++++++++++++++++++++++++++++++++++");
                //console.log(productList);
                //console.log(transferRecord);
                
            }
            //LOOP END
            setTransRecNum(false);
            setTransferRecord(productList);
            setProcessing(false);
        }catch(ers){
            console.log(ers); 
            setProcessing(false);
        }
    }
  return (
    <div className="tv-box">
        <h3>Pictures to letstrade</h3>
        <div className="row rs-item">
            <div className="col-md-6">
                 <div className="copy-box list-inner-block ">
                    <div className="add--rad-listing">
                       <h4>Picnic</h4>
                       <div className="total-ct"> {picCount}</div>
                       { 
                            procPicnic && (
                            <div className="img-source">
                                <img src={CONSTANTS.SMALL_PROCESSING} className="proc mgimageup" />
                            </div>)
                        }
                       {
                        listPicPictures && (
                            <ul className="no-bullet">
                                {listPicPictures.map((picture, i) => {
                                        return (<li key={i} className="list-item" >
                                                    <div className="flexme">
                                                        <div className="img-rad" style={{ backgroundImage: `url( ${picture.imgurl})` }}></div>
                                                        <div className="cotboxer pd10">
                                                            <div className="mini-title"><strong>{picture.title}</strong></div>
                                                            <div className=""> {picture.barcode} </div>
                                                        </div>
                                                    </div>
                                                </li>)
                                    })}
                            </ul>
                        )

                       }
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="copy-box list-inner-block ">
                        <div className="add--rad-listing">
                            <h4>LetsTrade</h4>
                            <div className="total-ct"> {tradeCount}</div>
                            { 
                                procTradeGo && (
                                    <div className="img-source">
                                        <img src={CONSTANTS.SMALL_PROCESSING} className="proc mgimageup" />
                                    </div>)
                                }
                            {
                                ltTradePictures && (
                                    <ul className="no-bullet">
                                        {ltTradePictures.map((picture, i) => {
                                                return (<li key={i} className="list-item" >
                                                            <div className="flexme">
                                                                <div className="img-rad" style={{ backgroundImage: `url( ${picture.image_url})` }}></div>
                                                                <div className="cotboxer pd10">
                                                                    <div className="mini-title"><strong>{picture.title}</strong></div>
                                                                    <div className=""> {picture.barcode} - {picture.status} </div>
                                                                </div>
                                                            </div>
                                                        </li>)
                                            })}
                                    </ul>
                                )

                            }
                      </div>
                </div>
            </div>
        </div>
        <div className="row rs-item">
            <div className="scroll-larger ">
                <h4>Picture (product) transfer</h4>
                {
                    (listPicPictures.length > 0) && (
                        <Button
                        label="Transfer Work"
                        className="btn btn-picky btsm1 mr-2"
                        onClick={() => executeTransferWork()}
                        disabled={processing}
                        />
                    )
                }
                 
                {
                    (transRecNum === true) && (
                        <div className="mgtop20 pr-item">
                            <span>{transferRecordV2.counter}</span>
                            <span>{transferRecordV2.title}</span>
                            <span>{transferRecordV2.Barcode}</span>
                            <span>{transferRecordV2.Status}</span>
                        </div>
                    )
                }
                {
                    (transferRecord.length > 0) && (
                        <table className="table table-striped mgtop20">
                            <thead>
                                <tr>
                                    <th className="tbl-head">#</th>
                                    <th className="tbl-head">Name</th>
                                    <th className="tbl-head">Barcode</th>
                                    <th className="tbl-head">Status</th>
                                </tr>                                
                            </thead>
                            <tbody>
                                {
                                        transferRecord.map((tranRec, loo) => {
                                        return (<tr key={loo} >
                                                
                                                    <td ><div className={"td-lister " + tranRec.classAct}>{1 + loo}</div></td>
                                                    <td className="alert-source-title">{tranRec.title}</td>
                                                    <td className="alert-source-title">{tranRec.Barcode}</td>
                                                    <td className="alert-source-title">{tranRec.Status}</td>
                                                
                                            </tr>)
                                        })
                                }
                            </tbody>
                        </table>
                        
                    )
                }
            </div>
        </div>
        {
            processing && (
                <div className="img-source">
                    <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                </div>
            )
        }
    </div>
  )
}

export default TBVSetPictoTrade