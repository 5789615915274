import React, { useContext, useEffect, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { AuthContext } from '../context/AuthContext';
import {DataTable } from 'primereact/datatable';
import {Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import DlgAddToken from "../components/DlgAddToken";
import { Badge } from 'primereact/badge';
 

function TokenList() {
  const { user } = useContext(AuthContext);
  const [tokenList, setTokenList] = useState([]);
  const [selectedToken, setSelectedToken] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [displayTokenDialog, setDisplayTokenDialog] = useState(false);
  const [catalogues, setCatalogues] = useState([]);
  const [selectedCatalogue, setSelectedCatalogue] = useState(null);

  useEffect(() => {
    fetchAlltheTokens();
    fetchAlltheCatalogues();
  },[])

  const fetchAlltheTokens = async () => {
   // console.log(user);

   try{
       const res = await axios.get(CONSTANTS.API_URL +"tokens/list", {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });
        console.log(res);
        console.log(res.data);
        setTokenList(res.data);

   }catch(erros){
    console.log("TOKENS ERROR");
    console.log(erros);
    if(erros.message === "Request failed with status code 403"){
      localStorage.removeItem("user_picknic2");
      document.location.href="/login";
     }
   }
  }

  const fetchAlltheCatalogues = async () => {
    try{
        const res = await axios.get(CONSTANTS.API_URL +"catalogues/list", {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });
          console.log(res);
          console.log(res.data);
          setCatalogues(res.data);
  
     }catch(errors){
      console.log("CATALOGUES ERROR");
      console.log(errors)
      }
  }
           

  const deleteToken = async (id) => {
    try{
        const res = await axios.delete(CONSTANTS.API_URL +"tokens/terminate/"+id, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });
          console.log(res);
          console.log(res.data);
          fetchAlltheTokens();
  
      }
      catch(erros){
        console.log("TOKENS ERROR");
        console.log(erros)
      }
  }

  const header = (
    <div className="table-header flex justify-content-between">
      <h4 className="mx-0 my-1">Tokens</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search Tokens..." />
      </span>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            {/* delete token */}
            <button className="btn btn-danger" onClick={() => deleteToken(rowData._id)}>
                <i className="pi pi-trash"></i>
            </button>
        </React.Fragment>
    );
}

return (
  <div className="main-standard">
    <DlgAddToken  
    displayTokenDialog={displayTokenDialog}
    setDisplayTokenDialog={setDisplayTokenDialog} 
    setSelectedCatalogue={setSelectedCatalogue} 
    selectedCatalogue={selectedCatalogue}
    catalogues={catalogues}
    user={user}
    fetchAlltheTokens={fetchAlltheTokens}
    />
     <h2 className="lbl-title-page">Tokens</h2>
     <div className="list-catalogue">
         <div className="card">
            <div className="card-body">
              <div className="grid">
              <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
               
               <div className="but-small pd10 mt-3 lg:mt-0">
                 <Button
                   label="Create Token"
                   className="p-button-danger p-button-outlined mr-2"
                   onClick={() => setDisplayTokenDialog(true)}
                 />
               </div>
             </div>
              {
                  tokenList && (
                    <DataTable
                    value={tokenList}
                    header={header}
                    className="min-w-full"
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} tokens"
                    responsiveLayout="scroll"
                    globalFilter={(globalFilter) ? globalFilter : ''}
                    selection={selectedToken}
                    onSelectionChange={e => setSelectedToken(e.value)}
                  >
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }} ></Column>
                      <Column field="token" header="Token"></Column>
                      <Column  header="Catalogue" body={(rowData) => rowData.catalogue[0].title}></Column>
                      <Column   header="Count" body={(rowdata )=> <Badge value={rowdata.count} severity="success" />}></Column>
                      <Column field="createdAt" header="Created At"></Column>
                      <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                  )
              }
              </div>
            </div>
         </div>

     </div> 
  </div>
)}

export default TokenList