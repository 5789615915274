//export const API_URL = "http://localhost:7171/api/";
export const API_URL = "https://demo.picnick.co.za/api/"
export const PREVIEW_IMAGE = "https://cjmarketing.co/image_directory/preview.png";
export const IMAGE_PROFILE = "https://newclient.cjdsa.co.za/images/base/profile.jpg";
export const IMAGE_PREV_SIZE = "https://cjmarketing.co/image_directory/size_example.png"
export const LOGIN_BACKGROUND = "https://cjmarketing.co/image_directory/cam_picnnic.png"
export const EXCEL_IMPORT = "https://api.picnick.co.za/assets/example_sheet.png"
export const SMALL_PROCESSING = "https://api.picnick.co.za/assets/smallprocess.gif"
export const TOKEN_IMAGE = "https://api.picnick.co.za/assets/tokenCreation.png"
export const EMAIL_URL = "http://api.picnick.co.za/emailing/email.php?"
export const EMAIL_CODE = "picnickCutterX";
//export const SESSION_COOKIE = "user_picknic_local_2";
export const SESSION_COOKIE = "user_picknic_local_live_2";
export const LETSTRADE1 = "https://lets-trade-product-dev.eu-gb.mybluemix.net/";
export const SALES_LAYER_CAT_FTCH = "https://picnick.co.za/picnick.co.za/saleslayers/prodfetch.php?categolayer=x2daefasefada";
export const SALES_LAYER_PIC_FTCH = "https://picnick.co.za/picnick.co.za/saleslayers/prodfetch.php?prodlayer=x2daefasefada";
export const SALES_LAYER_SUP_FTCH = "https://picnick.co.za/picnick.co.za/saleslayers/prodfetch.php?supplierlayer=x2daefasefada";
export const SALES_LAYER_PIC_CATY = "https://picnick.co.za/picnick.co.za/saleslayers/prodfetch.php?prodtaglayer=tt7dtagef765";
export const LTTOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzM2JkYTk1ZjMwNTQxOWFiMmZmNTZlZiIsIm5hbWUiOiJTZWxsc2NhcGUiLCJ0eXBlIjoiRU5USVRZIiwiaWF0IjoxNjY0ODY2OTY1fQ.O4t_e8uKNojVQAw0tQGAbYHUH6iIGnHzK2tg9tj8154";