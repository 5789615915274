import React, { useContext, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import './settings.css';
import TBVPictures from '../components/TBVPictures';
import TBVCatalogue from '../components/TBVCatalogue';
import TBVFormGal from '../components/TBVFormGal';
import { AuthContext } from '../context/AuthContext';
import TBVSetPresentation from '../components/TBVSetPresentation';
import TBVSetPictoTrade from '../components/TBVSetPictoTrade';

function Settings() {
    const [activeIndex, setActiveIndex] = useState(1);
    
    const { user } = useContext(AuthContext);

    //functions
    const toggleSelectedTab = (index) => {
        setActiveIndex(index);
    }   

  return (
    <div className="main-standard">
           <h2 className="lbl-title-page">Settings</h2>

           <div className="tab-dashboard-item">
                <div className="side-dash-settings-nav">
                   
                    <div  className={activeIndex === 1 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleSelectedTab(1)}>
                         Presentation
                    </div>
                    <div  className={activeIndex === 2 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleSelectedTab(2)}>
                         Pic to Trade
                    </div>
                </div>

                <div className="tab-content-box">
                        
                        <div className={activeIndex === 1 ? "content-tab content-active" : "content-tab"}>
                             <TBVSetPresentation person={user} />                           
                        </div>
                        <div
                            className={activeIndex === 2 ? "content-tab content-active" : "content-tab"}
                            >
                            <TBVSetPictoTrade person={user} />   
                        </div>
                </div>
           </div>               

    </div>
  )
}

export default Settings