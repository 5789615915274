import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { Link } from 'react-router-dom';

function DlgSearchPicture({searchMToggle, setSearchMToggle, members } ) {

    const searchPictureRef                          = useRef();

    const [searchResults,setSearchResults]          = useState();
    const [wrongMasge,setSomtWrongMessage]          = useState();

    const searchEnteredString = async () => {
       
        if(searchPictureRef.current.value){
        
            console.log(searchPictureRef.current.value );

            try{
                const res = await axios.get(CONSTANTS.API_URL +"pictures/search?searchfield="+ searchPictureRef.current.value , {
                    headers: {
                      token: "Bearer " + members.accessToken
                  }
                  });
                  //console.log("Divorces");
                  //console.log(res.data);
                  setSearchResults(res.data);
                  setSomtWrongMessage("");

            }catch(errs){
                console.log(errs);
                setSomtWrongMessage("Somethin went wrong please try again later.")
                
            }
        }else {
            //console.log("The search string is empty");
            setSearchResults([])
        }
    }

  return (
    <div className="block-comp">
        <Dialog visible={searchMToggle} onHide={() => {
            setSearchMToggle(false);
            setSearchResults([])
        }} style={{ width: '70vw' }}
            footer={
            <div>
                {/* link button */}              
                <Button className="btn btn-picky" label="Close" onClick={() => {
                     setSearchMToggle(false);
                     setSearchResults([])
                }} />
            </div>
            }>
                <div className="modal-body-content">
                    <div className="modal-srch ">
                        <h3>Search Photo</h3>
                        <div className="search-block flexme">
                                <input type="text" className="form-control search-pers2" ref={searchPictureRef} />
                                <button className="btn btn-picky btn-side-search" onClick={searchEnteredString}><FontAwesomeIcon icon={faSearch} /></button>
                        </div>
                        <div className="search-results pd10">
                            {
                                wrongMasge && (
                                    <div className="alert alert-danger">
                                        {wrongMasge}
                                    </div>
                                )
                            }
                            {
                                searchResults && (
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                            <th>Barcode</th>
                                            <th>Title</th>
                                            <th>Online Description</th>
                                            <th>Description</th>                                           
                                            <th>IMG1</th>
                                            <th>L</th>
                                            <th>R</th>
                                            <th>U</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {searchResults.map((result) => {
                                                return (<tr key={result._id}>
                                                    <td className="linl-st">
                                                       <Link to={"/photo/" + result._id} className="lk-dash">
                                                          {result.barcode}
                                                       </Link>
                                                    </td>
                                                    <td>
                                                       {result.title}
                                                    </td>
                                                    <td className="cellSmall1">
                                                       {result.onlineDesc}
                                                    </td>
                                                    <td className="cellSmall2">
                                                       {result.description}
                                                    </td>
                                                    <td className="linl-st">
                                                        <Link to={"/photo/" + result._id} className="lk-dash">
                                                        <img src={result.imgurl} className="small-stand-image" />
                                                       </Link>
                                                    </td>
                                                    <td>
                                                        {result.imgLeft && (
                                                             <img src={result.imgLeft} className="small-stand-image" />
                                                        )}                                                       
                                                    </td>
                                                    <td>
                                                        {result.imgRight && (
                                                             <img src={result.imgRight} className="small-stand-image" />
                                                        )}                                                       
                                                    </td>
                                                    <td>
                                                        {result.imgUnpack && (
                                                             <img src={result.imgUnpack} className="small-stand-image" />
                                                        )}                                                       
                                                    </td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                )
                            }
                        </div>
                    </div>
                </div>
        </Dialog>
    </div>
  )
}

export default DlgSearchPicture