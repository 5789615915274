import React, { useContext, useRef, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { AuthContext } from '../context/AuthContext';

function SupplierAdd() {
    const { user } = useContext(AuthContext);

    const [processing, setProcessing]       = useState(false);
    const [addMsg, setAddMsg]               = useState("");
    const [msgClass, setMsgClass]           = useState("");

    const supplierTitleRef                  = useRef();
    const supplierTextAreaRef               = useRef();

    async function handleFormSupplier(e) {
        e.preventDefault();  
        
        if(supplierTitleRef.current.value.length > 3  ){
            setAddMsg("");
            setProcessing(true);

            try{
               
                const suppItem = {
                    "title":supplierTitleRef.current.value,
                    "information": supplierTextAreaRef.current.value,
                    "slayer": ""
                }

                const response = await axios.post(CONSTANTS.API_URL+"suppliers/", suppItem, {
                    headers: {
                        token: "Bearer "+ user.accessToken
                    }
                });

                //console.log(response.data);
                
                supplierTitleRef.current.value     = "";
                supplierTextAreaRef.current.value  = "";
                setAddMsg("supplier has been created");
                setMsgClass("alert-success")
                setProcessing(false);
              }catch(err){
                setAddMsg("Something went wrong, please try again later.");
                setMsgClass("alert-danger")
                console.log(err.status);  
                setProcessing(false);              
              }

        }else {
            setAddMsg("Please add the title of the supplier, no less than 3 characters");
            setMsgClass("alert-danger")
        }
    }

  return (
    <div className="main-standard">
       <h2 className="lbl-title-page">Add Supplier </h2>
       <div className="list-supplier">
           <div className="card">
              <div className="card-body">
                 <form  onSubmit={handleFormSupplier}>
                     <div className="form-group">
                        <div className="lbldesc">Supplier Title*: </div>
                        <input type="text" ref={supplierTitleRef} className="form-control wfull" required/>
                     </div>
                     <div className="form-group">
                        <div className="lbldesc">Infomation: </div>
                        <textarea ref={supplierTextAreaRef} className="form-control wfull h100f"></textarea>                        
                     </div>
                     <div className="form-group">
                         <button type="submit" className="btn btn-picky" disabled={processing}>Create Supplier</button>
                     </div>
                     {
                        processing && (
                            <div className="img-source">
                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                            </div>
                        )
                     }
                     {
                        addMsg && (
                            <div className={"alert-area " + msgClass}>
                               {addMsg}
                            </div>
                        )
                      }
                 </form>
              </div>
           </div>

       </div> 
    </div>
  )
}

export default SupplierAdd