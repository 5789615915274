import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { useNavigate } from "react-router-dom";
import DlgCatUsers from "../components/DlgCatUsers";
import DlgImportTAssign from '../components/DlgImportTAssign';
import DlgAddTokenCat from '../components/DlgAddTokenCat';

function CatalogueDetails(props) {

  const [processing, setProcessing]                              = useState(false);
  const [pictures, setPictures]                                  = useState([]);
  const [selectedLinkedBarcodeIds, setSelectedLinkedBarcodeIds]  = useState([]);
  const [members, setMembers]                                    = useState([]);
  const [memeberIds, setMemberIds]                               = useState([]);
  const [allPictures, setAllPictures]                            = useState([]);
  const [catalogue, setCatalogue]                                = useState({});
  const [displayLinkDialog, setDisplayLinkDialog]                = useState(false);
  const [dsplyImprtAsignDialog, setDsplyImprtAsignDialog]        = useState(false);
  const [dspCreateCatTokenDialog, setDspCreateCatTokenDialog]    = useState(false);
  const [globalFilter, setGlobalFilter]                          = useState('');
  const [message, setMessage]                                    = useState("");
  const [messaClass, setMessClass]                               = useState("");

  const [selectedPictures, setSelectedPictures] = useState(null);
  const { user } = useContext(AuthContext);

  const [showDialogueUsers, setShowDialogueUsers]         = useState(false);

  const params = useParams();

  const [updateMe, setUpdateMe] = useState(0);
  const [userSetup, setUserSetup] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    //console.log("re ko start " + showDialogueUsers);
    getCatalogue();
    getAllPictures();
  }, [updateMe]);

  useEffect(() => {
      if(memeberIds){    
         performUserFectch();
         //console.log("________");
      }
      
  },[showDialogueUsers, userSetup]);


  const performUserFectch = async () => {
    setProcessing(true);
    
    const stringy = JSON.stringify(memeberIds);
  
      try{
         
          const res = await axios.get(CONSTANTS.API_URL + "catalogues/getusers?userssearch=" + stringy, {
            headers: {
              token: "Bearer " + user.accessToken
            }
          });
          
         // console.log(res.data.Users);
          setMembers(res.data.Users);
          setUpdateMe(updateMe + 1);
          setProcessing(false);
      } catch(err){
          console.log(err);
          setProcessing(false);
      }
      
  }

  const getCatalogue = async () => {
    setProcessing(true);
    try { 
      //console.log("Catalogue..." + params.id)
      // console.log(CONSTANTS.API_URL+"catalogues/details/" + params.id)
      const res = await axios.get(CONSTANTS.API_URL + "catalogues/details/" + params.id, {
        headers: {
          token: "Bearer " + user.accessToken
        }
      });
      
      
     //console.log("********>>>>>>>>>>>>>>>>>");
     //console.log(res.data[0]);
      setCatalogue(res.data[0]);
      console.log("********>>>>>>>>>>>>>>>>>");
      setPictures(res.data[0].pics);
      setMemberIds(res.data[0].userIds);
      setProcessing(false);
    } catch (err) {
      console.log(err);
      setProcessing(false);
      if(err.message === "Request failed with status code 403"){
        localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
        document.location.href="/login";
       } 
    }

  }
  const getAllPictures = async ()=> {
    try{

      const res = await axios.get(CONSTANTS.API_URL + "pictures/assembly" , {
        headers: {
          token: "Bearer " + user.accessToken
        }
      });

      setAllPictures(res.data);
    }catch(err){
      console.log(err);
    }
  }
  
  const searchAllPictures = async (e) => {
    setProcessing(true);
    try {
    
      //console.log("searchAllPictures Coaching..." + params.id);
      // console.log("#####" + e.target.value);
      //console.log(CONSTANTS.API_URL+"pictures/search?searchfield=" + e.target.value);
      const res = await axios.get(CONSTANTS.API_URL + "pictures/search?searchfield=" + e.target.value, {
        headers: {
          token: "Bearer " + user.accessToken
        }
      });

      setAllPictures(res.data);
      //console.log(res.data);
      setProcessing(false);
    } catch (err) {
      console.log(err);
      setProcessing(false);
    }
  }

  const linkPictures = async () => {
    // return ids from selectedLinkedBarcodeIds,
    const barcodeIds = selectedLinkedBarcodeIds.map((item) => {
      return item.barcode;
    });

    // append barcodeIds to catalogue.barcodeIds
    const newBarcodeIds = catalogue.barcodeIds.concat(barcodeIds);

    setProcessing(true);
    // update catalogue with new barcodeIds

    try {
      const res = await axios.put(CONSTANTS.API_URL + "catalogues/" + params.id, {
        barcodeIds: newBarcodeIds,
      }, {
        headers: {
          token: "Bearer " + user.accessToken
        }
      });
      //console.log("Updated catalogue");
      //console.log(res);
      setProcessing(false);
      setDisplayLinkDialog(false);
      setSelectedLinkedBarcodeIds([]);
      setAllPictures([]);
      setUpdateMe(updateMe + 1);
      
    } catch (err) {
      console.log(err);
      setProcessing(false);
    }
  }


  const unLinkPictures = async () => {
    // return ids from selectedLinkedBarcodeIds,
    const barcodeIds = pictures.map((item) => {
      return item.barcode;
    });

    // append barcodeIds to catalogue.barcodeIds
    const newBarcodeIds = catalogue.barcodeIds.filter((item) => {
      return !barcodeIds.includes(item);
    });

    setProcessing(true);
    // update catalogue with new barcodeIds

    try {
      const res = await axios.put(CONSTANTS.API_URL + "catalogues/" + params.id, {
        barcodeIds: newBarcodeIds,
      }, {
        headers: {
          token: "Bearer " + user.accessToken
        }
      });
      //console.log("Updated catalogue");
      //console.log(res);
      setProcessing(false);
      setDisplayLinkDialog(false);
      setSelectedLinkedBarcodeIds([]);
      setAllPictures([]);
      setUpdateMe(updateMe + 1);
    } catch (err) {
      console.log(err);
      setProcessing(false);
    }


  }

  const deleteCatalogue = async () => {
    setProcessing(true);
    // update catalogue with new barcodeIds

    try {
      const res = await axios.delete(CONSTANTS.API_URL + "catalogues/terminate/" + params.id ,{
        headers: {
          token: "Bearer " + user.accessToken
        }
      });
      //console.log(res.data);
      //console.log("Re deletele");
      setProcessing(false);
      navigate("/catalogue", { replace: true });

    }catch(err){
      setProcessing(false);
      console.log("Terminate Err");
      console.log(err);
    }
    
  }
  
  const viewCatalogueUsers = () => {
    setShowDialogueUsers(true);
  }

  const header = (
    <div className="table-header flex justify-content-between">
      <h4 className="mx-0 my-1">Catalogue Pictures</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" onClick={(e) => setGlobalFilter(e.target.value)}/>
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search Pictures..." />
      </span>
    </div>
  );

  const linkHeader = (
    <div className="table-header flex justify-content-between">
      <h4 className="mx-0 my-1">Search for pictures to link</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search"/>
        <InputText type="search" onInput={searchAllPictures} placeholder="Search Pictures..." />
      </span>
    </div>
  );


  const imageBodyTemplate = (rowData) => {
    //console.log(rowData);
    return <img src={rowData.imgurl} alt="image" className="product-image" width={80} />;
  }

  const linkStatusTemplate = (rowData) => {

    // if id is in catalogue.barcodeIds, return true
    //console.log("$$$$$$$$$$$$$$$$$$$$$$$$")
    // console.log(rowData);
    if (catalogue.barcodeIds.includes(rowData.barcode)) {
      return <Tag value="Linked" severity="success" />;
    }else{
      return <Tag value="Not Linked" severity="danger" />;
    }

  }

  const removeUserToListUsers = async (member) =>{
    setProcessing(true); 
    const memberId = member._id;
    const allMemberIds = memeberIds;
  
    const indexForDel = allMemberIds.indexOf(memberId);
    allMemberIds.splice(indexForDel, 1);
   
    try {
   
      var config = {
        method: 'delete',
        url: CONSTANTS.API_URL + 'catalogues/removeuser?srcdcat='+params.id,
        headers: { 
          'token': 'Bearer ' + user.accessToken,
          'Content-Type': 'application/json'
        },
        data : {
          "userIds": allMemberIds
        }
      };
      const res = await axios(config);
      //console.log(res.data);
      //console.log("Re deletele");
     
      setUserSetup(userSetup - 1);
      setProcessing(false);     

    }catch(err){
      setProcessing(false);
      console.log("Delete user from Catalogue Array Err");
      console.log(err);
    }

  }

  const addUserToListedUsers = async (member) => {
      //console.log("Parent");
      setProcessing(true);

      const memberId = member._id;
      // const newMemberIds = catalogue.userIds.concat(memberId);
      // console.log("China");
      // console.log(memberId);
      // const catId = catalogue._id;
      // console.log("hello" + newMemberIds);

      try{
      
       const allMemberIds = memeberIds;
       allMemberIds.push(memberId);

        var config = {
          method: 'post',
          url: CONSTANTS.API_URL + 'catalogues/addcheck?srcfcat='+params.id,
          headers: { 
            'token': 'Bearer ' + user.accessToken,
            'Content-Type': 'application/json'
          },
          data : {
            "userIds": allMemberIds
          }
        };
        const respo = await axios(config);
        /////pppppppppppp
        //const respo = await axios.post(CONSTANTS.API_URL + "catalogues/addcheck?srcfcat=" + catalId);
        //console.log("coooos");
        console.log(respo.data);
        setUserSetup(userSetup + 1);
        setProcessing(false);
    }catch(erUser){
        console.log("Dialogue Error user");
        console.log(erUser);
        setProcessing(false);
        
    }
  }
  const processSalesLayerTage = async () => {
    setProcessing(true);
    try{
      setMessClass("");
        setMessage("");

      const resCat= await axios.get(CONSTANTS.SALES_LAYER_PIC_CATY + "&protag=" + catalogue.salescode);
       console.log(resCat);
       if(resCat.status === 200){
        setMessClass("alert alert-warning");
        setMessage("Still Processing...");
        setProcessing(true);
        const newBar = {
          "catid" : catalogue._id,
          "barcodes" : resCat.data.barcodes
        }
        console.log(newBar);
       
        const repCatalogueUpdate = await axios.post(CONSTANTS.API_URL + "catalogues/barcodeupdate", newBar, {
          headers: {
            'token': 'Bearer '+ user.accessToken,
            'Content-Type': 'application/json'
          },
        });
        console.log("Go Hard");
        console.log(repCatalogueUpdate);
        console.log(repCatalogueUpdate.data);
        setUpdateMe(updateMe + 1);
        setMessClass("");
        setMessage("");
        setProcessing(false);
       }else {
        setMessClass("alert alert-danger");
        setMessage("We are experiencing server issues, Please try again later.");
       }
       setProcessing(false);
    } catch(err){
      console.log(err);
      setProcessing(false);
    }
  }
  return (
          <div className="main-standard">
           <h2 className="lbl-title-page">Catalogue Detials</h2>
              <div className="card">

              
                <DlgImportTAssign dsplyImprtAsignDialog={dsplyImprtAsignDialog} setDsplyImprtAsignDialog={setDsplyImprtAsignDialog} catid={params.id}  person={user} />

                <Dialog visible={displayLinkDialog} onHide={() => setDisplayLinkDialog(false)} style={{ width: '50vw' }}
                  footer={
                    <div>
                      {/* link button */}
                      <Button label="Link" icon="pi pi-link" onClick={linkPictures} disabled={selectedLinkedBarcodeIds.length === 0} />
                      <Button className="p-button-danger" label="Close" onClick={() => setDisplayLinkDialog(false)} />
                    </div>
                  }>
                    {allPictures && (
                        <DataTable value={allPictures} paginator rows={10} rowsPerPageOptions={[5, 10, 20]}
                          header={linkHeader}
                          globalFilter={globalFilter}
                          emptyMessage="No pictures found"
                          className="p-datatable-sm"
                          selection={selectedLinkedBarcodeIds}
                        onSelectionChange={
                          (e) => {
                            console.log(e.value)
                            setSelectedLinkedBarcodeIds(e.value)
                          }}
                      >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}
                        />
                        <Column header="Image" body={imageBodyTemplate}></Column>
                        <Column field="barcode" header="Barcode"></Column>
                        <Column field="title" header="Title"></Column>
                        <Column header="Link Status" body={linkStatusTemplate}></Column>
                      </DataTable>
                    )}
                  
                </Dialog>

                                  
                <DlgCatUsers showDialogueUsers={showDialogueUsers} setShowDialogueUsers={setShowDialogueUsers} addUserToListedUsers={addUserToListedUsers} removeUserToListUsers={removeUserToListUsers} members={members} memeberIds={memeberIds} />
              
                <DlgAddTokenCat dspCreateCatTokenDialog={dspCreateCatTokenDialog} setDspCreateCatTokenDialog={setDspCreateCatTokenDialog} catid={params.id} person={user} />

                <div className="desc-content">
                    <div className="font-medium text-3xl text-900 mb-3">{catalogue && catalogue.title}</div>
                    <div className="text-500 mb-5">{catalogue &&  catalogue.information}</div>
                  </div>
                  <div className="mssagepads">
                        {
                          message && (
                            <div className={messaClass}>
                              {message}
                            </div>
                          )
                          
                        }
                  </div>
                <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                 
                  <div className="but-small pd10 mt-3 lg:mt-0">
                    <Button
                      label="Import pictures"
                      className="btn btn-picky btsm1 mr-2"
                      onClick={() => setDsplyImprtAsignDialog(true)}
                    />
                    <Button
                      className="btn btn-picky btsm1 mr-2"
                      label="Link Pictures"
                      onClick={() => setDisplayLinkDialog(true)}
                    />

                    <Button
                      className="btn btn-picky btsm1 mr-2"
                      label="Un-Link Pictures"
                      onClick={unLinkPictures}
                    />
                    <Button
                      className="btn btn-picky btsm1 mr-2"
                      label="View Catalogue Users"
                      onClick={viewCatalogueUsers}
                    />
                    <Button
                      label="Token"
                      className="btn btn-picky btsm1 mr-2"
                      onClick={() => setDspCreateCatTokenDialog(true)}
                    />
                    
                    {
                      catalogue.salestag && 
                      <Button
                      label="Update Catlogue"
                      className="btn btn-picky btsm1 mr-2"
                      onClick={() => processSalesLayerTage()}
                    />
                    }
                  </div>
                </div>
                <div>
                  <DataTable
                    value={pictures}
                    header={header}
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    responsiveLayout="scroll"
                    globalFilter={(globalFilter) ? globalFilter : ''}
                    selection={selectedPictures}
                    onSelectionChange={e => setSelectedPictures(e.value)}
                  >
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }} ></Column>
                    <Column header="Image" body={imageBodyTemplate}></Column>
                    <Column field="barcode" header="Barcode"></Column>
                    <Column field="title" header="Title"></Column>
                  </DataTable>
                </div>
                {
                  processing && (
                      <div className="img-source">
                      <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc profixed" />
                      </div>
                  )
              }
              </div>
              {
                user.isAdmin && (
                  <div className="bottom-foot">
                      <Button
                              className="btn btn-picky mr-2"
                              label="Delete Catalogue"
                              onClick={deleteCatalogue}
                            />
                    </div>
                )
              }
              
          
          </div>
  );
}

export default CatalogueDetails